import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* istanbul ignore file */
import { useRef, useId } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, makeStyles, shorthands, Title1, Tab, TabList, } from "@fluentui/react-components";
import { ArrowUpload24Filled, DrawerArrowDownload24Regular, AppsList20Regular, ArrowUpload20Regular, } from "@fluentui/react-icons";
import { uploadJobs, setTab } from "../../redux/jobsSlice";
import { FileUploader } from "../Controls";
import { Card } from "../../library/src";
import JobList from "./JobList";
import JobsHeader from "./JobsHeader";
import { TabOptions } from "./JobUtils";
import { rootPage } from "../shared/sharedStyles";
import { useUserRoles } from "../../hooks";
const useStyles = makeStyles({
    pageTitle: {
        paddingLeft: "0.625rem",
        paddingTop: "1.8rem",
    },
    tab: {
        paddingTop: "1rem",
    },
    tabPanel: {
        flexGrow: 1,
        overflowY: "auto",
        height: "calc(100vh - 19rem)",
        ...shorthands.padding("1rem"),
    },
});
const Jobs = () => {
    const styles = useStyles();
    const rootStyles = rootPage();
    const { tab } = useSelector((state) => state.jobs);
    const dispatch = useDispatch();
    const jobCardId = useId();
    const uploader = useRef();
    const menuCard = [
        {
            key: "menu-job-select-files",
            name: "Select Jobs",
            icon: _jsx(DrawerArrowDownload24Regular, {}),
            onClick: () => {
                var _a;
                (_a = uploader === null || uploader === void 0 ? void 0 : uploader.current) === null || _a === void 0 ? void 0 : _a.onClickPrimary();
            },
        },
        {
            key: "menu-job-push-jobs",
            name: "Push Jobs",
            icon: _jsx(ArrowUpload24Filled, {}),
            onClick: () => {
                dispatch(uploadJobs());
            },
        },
    ];
    const { tenantAdmin, siteAdmin, jobDesigner, consumer } = useUserRoles();
    const uploadJobEnabled = tenantAdmin || siteAdmin || jobDesigner;
    const showJobs = tenantAdmin || siteAdmin || jobDesigner || consumer;
    const onTabSelect = (event, data) => {
        dispatch(setTab(data.value));
    };
    const content = (_jsxs(_Fragment, { children: [_jsx(Title1, { className: styles.pageTitle, children: "Jobs" }), _jsxs(TabList, { className: styles.tab, selectedValue: tab, onTabSelect: onTabSelect, children: [_jsx(Tab, { id: "JobList", value: TabOptions.JOBS, "data-testid": "job-list-tab", icon: _jsx(AppsList20Regular, {}), disabled: !showJobs, children: "Jobs" }, "Jobs"), uploadJobEnabled && (_jsx(Tab, { id: "JobUpload", value: TabOptions.UPLOAD, "data-testid": "job-upload-tab", icon: _jsx(ArrowUpload20Regular, {}), children: "Upload" }, "Upload"))] }), tab === TabOptions.JOBS && showJobs && (_jsx("div", { className: styles.tabPanel, "data-testid": "jobs-list", role: "tabpanel", "aria-labelledby": "JobList", children: _jsx(JobList, {}) })), tab === TabOptions.UPLOAD && (_jsx("div", { className: styles.tabPanel, "data-testid": "jobs-upload", role: "tabpanel", "aria-labelledby": "JobUpload", children: _jsx(Card, { id: jobCardId, headerText: "Load Jobs", menuItems: menuCard, children: _jsx(FileUploader, { ref: uploader, text: "Select Jobs Files", textSecondary: "Send Jobs", dragTarget: jobCardId, successMessage: "Job files uploaded successfully", showButtons: false }) }) }))] }));
    return showJobs ? (_jsxs("div", { className: rootStyles.root, children: [_jsx("div", { className: rootStyles.header, children: _jsx(JobsHeader, {}) }), _jsx(Divider, { className: rootStyles.divider }), _jsx("div", { className: rootStyles.body, children: content })] })) : (_jsx(_Fragment, {}));
};
export default Jobs;
