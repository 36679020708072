import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from "@apollo/client";
import { Divider, Spinner } from "@fluentui/react-components";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Details, SitesDropdown } from "../../library/src";
import { ErrorDisplay } from "../Error";
import { ErrorMessages } from "../Error/errorMessages";
import { rootPage as useStyles } from "../shared/sharedStyles";
import SystemLinkDetails from "./Details/SystemLinkDetails";
import SystemLinksCards from "./SystemLinksCards";
import { setSelectedSite } from "../../redux/appStateSlice";
const QUERY = gql `
  query getSystemLinks($tenantIdOrKey: String!) {
    tenant(tenantIdOrKey: $tenantIdOrKey) {
      sites {
        id
        name
        description
        systemLinks {
          id
          systemId
          systemName
          alias
        }
      }
      systems {
        id
        name
        type
        viaGateway
      }
      id
      key
    }
  }
`;
const initialSystemLinkState = {
    id: "",
    systemId: "",
    systemName: "",
    alias: "",
};
const SystemLinks = () => {
    var _a;
    const styles = useStyles();
    const dispatch = useDispatch();
    const { id, sites } = useSelector((state) => state.tenant);
    const { principalId } = useSelector((state) => state.userInfo);
    const { selectedSite } = useSelector((state) => state.appState);
    const sitesOptions = sites ? sites.map((site) => { var _a, _b; return ({ text: (_a = site.name) !== null && _a !== void 0 ? _a : "", id: (_b = site.id) !== null && _b !== void 0 ? _b : "" }); }) : [];
    const { loading, error, data } = useQuery(QUERY, {
        variables: { tenantIdOrKey: id },
    });
    const [openDetailView, setOpenDetailView] = useState(false);
    const [systemLinkInDetailView, setSystemLinkInDetailView] = useState(initialSystemLinkState);
    let content;
    if (loading || !principalId) {
        content = _jsx(Spinner, { label: "Loading...", size: "huge", className: styles.spinner });
    }
    else if (error) {
        content = _jsx(ErrorDisplay, { error: { message: error === null || error === void 0 ? void 0 : error.message } });
    }
    else if (data.tenant === null) {
        content = _jsx(ErrorDisplay, { error: { message: ErrorMessages.invalidTenant } });
    }
    else {
        content = (_jsx(SystemLinksCards, { tenantIdOrKey: data.tenant.key, systems: data.tenant.systems, systemLinks: (_a = data.tenant.sites.filter((site) => site.id === selectedSite.id)[0]) === null || _a === void 0 ? void 0 : _a.systemLinks, setOpenDetailView: setOpenDetailView, setSystemLinkInDetailView: setSystemLinkInDetailView }));
    }
    const selectSite = (value) => {
        var _a, _b;
        const newSelectedSite = sites === null || sites === void 0 ? void 0 : sites.find((site) => site.id === value);
        dispatch(setSelectedSite({ id: (_a = newSelectedSite === null || newSelectedSite === void 0 ? void 0 : newSelectedSite.id) !== null && _a !== void 0 ? _a : "", name: (_b = newSelectedSite === null || newSelectedSite === void 0 ? void 0 : newSelectedSite.name) !== null && _b !== void 0 ? _b : "" }));
    };
    return (_jsxs("div", { className: styles.root, "data-testid": "cards-system-links", children: [_jsx(Details, { isOpen: openDetailView, title: `Details ${systemLinkInDetailView.alias}`, onDismiss: () => {
                    setOpenDetailView(false);
                }, children: _jsx(Details.Body, { children: _jsx(SystemLinkDetails, { systemLink: systemLinkInDetailView }) }) }), _jsx("div", { className: styles.header, children: _jsx(SitesDropdown, { options: sitesOptions, defaultValueId: selectedSite.id, onOptionSelect: selectSite }) }), _jsx(Divider, { className: styles.divider }), _jsx("div", { className: styles.body, children: content })] }));
};
export default SystemLinks;
