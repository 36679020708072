import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Divider, makeStyles, shorthands, Text, tokens } from "@fluentui/react-components";
import { ChevronLeft20Filled } from "@fluentui/react-icons";
import { ContainerHeaderHeight, DefaultPadding } from "../shared/sharedStyles";
const useStyles = makeStyles({
    headerSettings: {
        display: "flex",
        height: ContainerHeaderHeight,
        ...shorthands.padding(0, DefaultPadding),
        flexDirection: "column",
    },
    back: {
        display: "flex",
        flexGrow: 1,
    },
    link: {
        display: "flex",
        alignItems: "center",
    },
    icon: {
        color: tokens.colorBrandForeground2,
        paddingLeft: "0.9375rem",
        paddingRight: "0.625rem",
        ":hover": { cursor: "pointer" },
    },
    text: {
        fontSize: "1.0625rem",
        display: "flex",
        alignItems: "center",
    },
    divider: {
        flexGrow: 0,
    },
});
const HeaderBarSettings = () => {
    const styles = useStyles();
    const { comeBackLocationPath } = useSelector((state) => state.appState);
    let comeBackUrl = comeBackLocationPath !== null && comeBackLocationPath !== void 0 ? comeBackLocationPath : `/${sessionStorage.getItem("comeBackLocationPath")}`;
    if (!comeBackUrl) {
        const pathname = window.location.pathname;
        comeBackUrl = pathname.length > 1 ? `/${pathname.split("/")[1]}` : "/";
    }
    return (_jsxs("div", { "data-testid": "header-bar-settings", className: styles.headerSettings, children: [_jsxs("div", { "data-testid": "back-button", className: styles.back, children: [_jsx(Link, { to: comeBackUrl, className: styles.link, children: _jsx(ChevronLeft20Filled, { "data-testid": "back-icon", className: styles.icon }) }), _jsx(Text, { className: styles.text, children: "Back" })] }), _jsx(Divider, { className: styles.divider })] }));
};
export default HeaderBarSettings;
