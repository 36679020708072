import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from "@apollo/client";
import { makeStyles, mergeClasses, shorthands, Spinner, Tab, TabList, Title1, tokens, } from "@fluentui/react-components";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PrincipalList, SitesDropdown } from "../../../library/src";
import { UserRoles } from "../../../redux/userInfoSlice";
import { ErrorDisplay } from "../../Error";
import { rootPage as useRootStyles, settingsPage as useSettingsStyles } from "../../shared/sharedStyles";
const QUERY = gql `
  query principals($tenantIdOrKey: String!) {
    principals(tenantIdOrKey: $tenantIdOrKey) {
      displayName
      email
      id
      principalType
      roleAssignments {
        principalId
        roleId
        siteId
        tenantId
      }
    }
  }
`;
var UsersGroupName;
(function (UsersGroupName) {
    UsersGroupName["Assigned"] = "Assigned";
    UsersGroupName["SiteAdministrators"] = "Site Administrators";
    UsersGroupName["JobDesigners"] = "Job Designers";
    UsersGroupName["Consumers"] = "Consumers";
    UsersGroupName["Unassigned"] = "Unassigned";
})(UsersGroupName || (UsersGroupName = {}));
const useStyles = makeStyles({
    root: {
        height: "fill-available",
        ...shorthands.overflow("hidden"),
    },
    principalListWrapper: {
        height: "calc(100vh - 19rem)",
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.padding("0.5rem", "1rem"),
        ...shorthands.borderRadius("0.5rem"),
        marginTop: "1rem",
        marginLeft: "0.625rem",
        overflowY: "hidden",
    },
    siteSelectorWrapper: {
        position: "absolute",
        top: "3rem",
        height: "3rem",
        display: "flex",
        alignItems: "center",
    },
    siteSelector: {
        backgroundColor: tokens.colorNeutralBackground2,
        paddingLeft: "1rem",
    },
    tenantAdminInfoText: {
        color: tokens.colorNeutralForeground4,
        fontSize: "0.625rem",
        marginLeft: "0.625rem",
        ...shorthands.padding("0.5rem", "1rem"),
    },
    italic: {
        fontStyle: "italic",
    },
});
const UsersRoles = () => {
    const styles = useStyles();
    const rootStyles = useRootStyles();
    const settingsStyles = useSettingsStyles();
    const { id: tenantId, sites } = useSelector((state) => state.tenant);
    const [selectedSite, setSelectedSite] = useState({ id: "", name: "" });
    const sitesOptions = sites ? sites.map((site) => { var _a, _b; return ({ text: (_a = site.name) !== null && _a !== void 0 ? _a : "", id: (_b = site.id) !== null && _b !== void 0 ? _b : "" }); }) : [];
    const [usersGroups, setUsersGroups] = useState(Object.values(UsersGroupName).map((name) => ({
        name,
    })));
    const [selectedUsersGroup, setSelectedUsersGroup] = useState(usersGroups[0]);
    const [principals, setPrincipals] = useState([]);
    const [groupedPrincipals, setGroupedPrincipals] = useState([]);
    const divRef = useRef(null);
    const { loading, error } = useQuery(QUERY, {
        variables: { tenantIdOrKey: tenantId },
        onCompleted(data) {
            setPrincipals(data.principals);
        },
    });
    const selectSite = (value) => {
        var _a;
        const newSelectedSite = (_a = sites === null || sites === void 0 ? void 0 : sites.find((site) => site.id === value)) !== null && _a !== void 0 ? _a : { id: "", name: "" };
        setSelectedSite(newSelectedSite);
    };
    const groupPrincipals = (usersGroupType = selectedUsersGroup.name) => {
        let groupedPrincipals;
        switch (usersGroupType) {
            case UsersGroupName.Assigned:
                groupedPrincipals = principals.filter((principal) => {
                    return principal.roleAssignments.some((role) => role.siteId == selectedSite.id || (!selectedSite.id && role.siteId));
                });
                break;
            case UsersGroupName.SiteAdministrators:
                groupedPrincipals = principals.filter((principal) => {
                    return principal.roleAssignments.some((role) => role.roleId == UserRoles.SiteAdmin && (role.siteId == selectedSite.id || !selectedSite.id));
                });
                break;
            case UsersGroupName.JobDesigners:
                groupedPrincipals = principals.filter((principal) => {
                    return principal.roleAssignments.some((role) => role.roleId == UserRoles.JobDesigner && (role.siteId == selectedSite.id || !selectedSite.id));
                });
                break;
            case UsersGroupName.Consumers:
                groupedPrincipals = principals.filter((principal) => {
                    return principal.roleAssignments.some((role) => role.roleId == UserRoles.Consumer && (role.siteId == selectedSite.id || !selectedSite.id));
                });
                break;
            case UsersGroupName.Unassigned:
                if (selectedSite.id) {
                    groupedPrincipals = principals.filter((principal) => {
                        return !principal.roleAssignments.some((role) => role.siteId === selectedSite.id);
                    });
                }
                else {
                    groupedPrincipals = principals.filter((principal) => {
                        return !principal.roleAssignments.some((role) => !!role.siteId);
                    });
                }
                break;
            default:
                groupedPrincipals = [];
        }
        return groupedPrincipals;
    };
    const countUsersGroups = () => {
        setUsersGroups((usersGroups) => usersGroups.map((usersGroup) => ({
            ...usersGroup,
            count: groupPrincipals(usersGroup.name).length,
        })));
    };
    useEffect(() => {
        setGroupedPrincipals(groupPrincipals());
    }, [selectedUsersGroup, selectedSite, principals]);
    useEffect(() => {
        countUsersGroups();
    }, [principals, selectedSite]);
    return (_jsxs("div", { className: mergeClasses(rootStyles.root, styles.root), children: [_jsx("div", { className: styles.siteSelectorWrapper, children: _jsx("div", { className: styles.siteSelector, children: !loading && (_jsx(SitesDropdown, { options: sitesOptions, onOptionSelect: selectSite, showAllSitesOption: true, selectAllSitesByDefault: true })) }) }), _jsx("div", { className: rootStyles.body, children: loading ? (_jsx(Spinner, { label: "Loading...", size: "huge", className: rootStyles.spinner })) : error ? (_jsx(ErrorDisplay, { error: { message: error === null || error === void 0 ? void 0 : error.message } })) : (_jsxs("div", { className: rootStyles.wrapper, children: [_jsx("div", { className: settingsStyles.header }), _jsx(Title1, { className: rootStyles.pageTitle, children: "Users & Roles" }), _jsx("div", { className: rootStyles.tabList, children: _jsx(TabList, { defaultSelectedValue: selectedUsersGroup.name, appearance: "transparent", "data-testid": "grouping-pane", children: usersGroups.map((group) => {
                                    return (_jsxs(Tab, { value: group.name, onClick: () => {
                                            setSelectedUsersGroup(group);
                                        }, children: [`${group.name}`, group.count !== undefined && ` (${group.count})`] }, group.name));
                                }) }) }), _jsx("div", { className: styles.principalListWrapper, ref: divRef, children: _jsx(PrincipalList, { principals: groupedPrincipals, sites: sites !== null && sites !== void 0 ? sites : [], selectedSite: selectedSite.name ? selectedSite : undefined, parentRef: divRef }) }), _jsxs("div", { className: styles.tenantAdminInfoText, children: ["Tenant Admins are managed on a separate page. Please navigate to the", " ", _jsx("span", { className: styles.italic, children: "General" }), " section."] })] })) })] }));
};
export default UsersRoles;
