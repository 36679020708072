import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import { ChevronDown20Regular, ChevronUp20Regular, Filter16Regular } from "@fluentui/react-icons";
import "./filter.scss";
const useStyles = makeStyles({
    filter: {
        ...shorthands.padding("2px"),
    },
    filterApplied: {
        position: "relative",
        '::before': {
            content: '""',
            position: "absolute",
            backgroundColor: tokens.colorBrandForegroundLink,
            width: "8px",
            height: "8px",
            top: "1px",
            right: "2px",
            ...shorthands.border("1px", "solid", "white"),
            ...shorthands.borderRadius("50%"),
        },
    }
});
const Filter = ({ filter, clear, children, onToggle, disabled = false, applied = false, filteredItemsCount = 0, }) => {
    const [open, setOpen] = useState(false);
    const styles = useStyles();
    const clearOnClick = () => {
        clear();
    };
    const filterOnClick = () => {
        filter();
    };
    const showNumberFiltered = () => applied ? `(${filteredItemsCount})` : "";
    return (_jsx(_Fragment, { children: _jsx("div", { className: "filter-button", children: _jsxs("div", { className: `filter-button__wrapper ${open ? "filter-button__wrapper--open" : ""}`, children: [_jsxs("div", { className: "filter-button__wrapper__icons", "data-testid": "filter-button", onClick: () => {
                            setOpen(!open);
                            if (onToggle)
                                onToggle(!open);
                        }, children: [_jsx("div", { className: mergeClasses(applied && styles.filterApplied), children: _jsx(Filter16Regular, { className: styles.filter }) }), _jsx("span", { className: "filter-button__wrapper__icons__text", children: `Filter ${showNumberFiltered()}` }), open ? (_jsx(ChevronUp20Regular, { "data-testid": "filter-chevron-up" })) : (_jsx(ChevronDown20Regular, { "data-testid": "filter-chevron-down" }))] }), open && (_jsxs("div", { className: "filter-button__wrapper__container", "data-testid": "filter-container", children: [_jsx("div", { className: "filter-button__wrapper__container__header", "data-testid": "filter-header" }), _jsx("div", { className: "filter-button__wrapper__container__body", "data-testid": "filter-body", children: children }), _jsxs("div", { className: "filter-button__wrapper__container__footer", "data-testid": "filter-footer", children: [_jsxs("button", { className: `filter-button__wrapper__container__footer__button-filter${disabled ? "--disabled" : ""}`, "data-testid": "footer-filter-button", onClick: filterOnClick, disabled: disabled, children: ["Filter ", showNumberFiltered()] }), _jsx("button", { className: `filter-button__wrapper__container__footer__button-clear${disabled ? "--disabled" : ""}`, "data-testid": "footer-clear-button", onClick: clearOnClick, disabled: disabled, children: "Clear" })] })] }))] }) }) }));
};
export default Filter;
