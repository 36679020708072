var SystemKind;
(function (SystemKind) {
    SystemKind["NULL"] = "";
    SystemKind["SAP"] = "SAP";
    SystemKind["Database"] = "DATABASE";
    SystemKind["MSDataverse"] = "MS_DATAVERSE";
    SystemKind["DevOps"] = "AZURE_DEV_OPS";
    SystemKind["ALL"] = "All";
})(SystemKind || (SystemKind = {}));
export { SystemKind };
