import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Divider, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, makeStyles, mergeClasses, tokens, } from "@fluentui/react-components";
import { MoreHorizontal16Filled } from "@fluentui/react-icons";
const useStyles = makeStyles({
    menuButton: {
        height: "32px",
        "&:hover": {
            backgroundColor: tokens.colorNeutralBackground1Pressed,
            "& svg": {
                color: tokens.colorNeutralForeground2Pressed,
            },
        },
    },
    menuButtonWarning: {
        "&:hover": {
            backgroundColor: tokens.colorNeutralBackground1Pressed,
            "& svg": {
                color: tokens.colorPaletteRedForeground1,
            },
        },
    },
});
const CardMenu = ({ menuItems, warning, mountModule }) => {
    const styles = useStyles();
    const generateMenuItems = () => menuItems === null || menuItems === void 0 ? void 0 : menuItems.map((menuItem) => {
        let resultMenuItem = (_jsx(MenuItem, { icon: menuItem.icon, disabled: menuItem.disabled, onClick: () => menuItem.onClick(), children: menuItem.name }, menuItem.key));
        if (menuItem.divider) {
            resultMenuItem = [resultMenuItem, _jsx(Divider, { "data-testid": "card-menu-divider" }, `${menuItem.key}-divider`)];
        }
        return resultMenuItem;
    });
    // <Menu mountNode={mountModule}>   Created a jira bug to resolve this problem: CP-1314
    return (_jsxs(Menu, { children: [_jsx(MenuTrigger, { disableButtonEnhancement: true, children: _jsx(Button, { "data-testid": "menu-button", appearance: "subtle", className: mergeClasses(styles.menuButton, warning && styles.menuButtonWarning), icon: _jsx(MoreHorizontal16Filled, {}) }) }), _jsx(MenuPopover, { children: _jsx(MenuList, { children: generateMenuItems() }) })] }));
};
export default CardMenu;
