import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from "@apollo/client/core";
import { Text, Title1, mergeClasses } from "@fluentui/react-components";
import { Delete20Regular, Eye20Regular, ServerMultiple20Regular } from "@fluentui/react-icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "../../library/src";
import { navigateToSettingsPages } from "../../redux/appStateSlice";
import { setAndApplyGatewayIdFilter, setAndApplySystemIdFilter } from "../../redux/systemsFilterSlice";
import emptySystemLinks from "../SystemLinks/emptySystemLinks.svg";
import DeleteDialog from "../shared/sharedDeleteUtils";
import { emptyBody as useEmptyStyles, rootPage as useRootStyles, cardsBody as useStyles } from "../shared/sharedStyles";
import { SystemIcon } from "../Settings/Systems";
import { useUserRoles } from "../../hooks";
const NoSystemLinks = () => {
    const styles = useEmptyStyles();
    return (_jsx(_Fragment, { children: _jsxs("div", { className: styles.emptyContainer, children: [_jsx("img", { "data-testid": "system-links-empty-image", className: styles.image, src: emptySystemLinks, alt: "No System Links" }), _jsx("div", { className: styles.text, children: "You don't have a System Link. Please add one." })] }) }));
};
const SystemLinkInfo = ({ tenantIdOrKey, systemId, systemName, systems, dispatch }) => {
    const styles = useStyles();
    const { icon, viaGateway } = getSystemLinkInfo(systemName, systems);
    const { tenantAdmin } = useUserRoles();
    return (_jsx("div", { className: styles.cardBody, children: _jsxs("div", { className: mergeClasses(styles.cardBodyItem), children: [_jsxs("div", { className: mergeClasses(styles.line, tenantAdmin && styles.link), "data-testid": "system-link-icon", onClick: (e) => {
                        e === null || e === void 0 ? void 0 : e.preventDefault();
                        if (tenantAdmin) {
                            dispatch(setAndApplySystemIdFilter(systemId));
                            dispatch(navigateToSettingsPages(`/${tenantIdOrKey}/settings/systems`));
                        }
                    }, children: [icon, _jsx(Text, { truncate: true, wrap: false, className: mergeClasses(tenantAdmin && styles.linkText), children: systemName })] }), !!viaGateway && (_jsxs("div", { className: mergeClasses(styles.line, tenantAdmin && styles.link), onClick: (e) => {
                        e === null || e === void 0 ? void 0 : e.preventDefault();
                        if (tenantAdmin) {
                            dispatch(setAndApplyGatewayIdFilter(viaGateway));
                            dispatch(navigateToSettingsPages(`/${tenantIdOrKey}/settings/gateways`));
                        }
                    }, children: [_jsx(ServerMultiple20Regular, {}), _jsx("div", { className: mergeClasses(tenantAdmin && styles.linkText), children: "Via Gateway" })] }))] }) }));
};
const getSystemLinkInfo = (systemName, systems) => {
    var _a;
    const system = systems.find((system) => system.name === systemName);
    return { icon: _jsx(SystemIcon, { type: system === null || system === void 0 ? void 0 : system.type }), viaGateway: (_a = system === null || system === void 0 ? void 0 : system.viaGateway) !== null && _a !== void 0 ? _a : "" };
};
const SystemLinksCards = ({ tenantIdOrKey, systemLinks, systems, setOpenDetailView, setSystemLinkInDetailView, }) => {
    const styles = useStyles();
    const rootStyles = useRootStyles();
    const dispatch = useDispatch();
    const [systemLinkToBeDeleted, setSystemLinkToBeDeleted] = useState();
    const { siteAdmin, tenantAdmin } = useUserRoles();
    const { selectedSite } = useSelector((state) => state.appState);
    const DELETE_SYSTEM_LINK = gql `
    mutation DeleteSystemLink($input: DeleteSystemLinkInput!) {
      deleteSystemLink(input: $input) {
        systemLinkId
      }
    }
  `;
    const menuCard = (systemLink) => {
        const menuItems = [
            {
                key: "system-link-menu-card-1",
                name: "Details",
                icon: _jsx(Eye20Regular, {}),
                onClick: () => {
                    setOpenDetailView(true);
                    setSystemLinkInDetailView(systemLink);
                },
            },
        ];
        if (siteAdmin || tenantAdmin) {
            menuItems.push({
                key: "system-link-menu-card-2",
                name: "Remove",
                icon: _jsx(Delete20Regular, {}),
                onClick: () => {
                    setSystemLinkToBeDeleted(systemLink);
                },
            });
        }
        return menuItems;
    };
    return (_jsxs(_Fragment, { children: [systemLinkToBeDeleted && (_jsx(DeleteDialog, { itemName: systemLinkToBeDeleted.alias, itemId: systemLinkToBeDeleted.id, title: `Remove the System Link ${systemLinkToBeDeleted.alias}`, message: "Do you really want to remove this System Link?", deletingMessage: "Removing the System Link", successMessage: `The System Link ${systemLinkToBeDeleted.alias} has been successfully removed.`, errorMessageTitle: "An error occurred while attempting to remove the System Link", mutation: DELETE_SYSTEM_LINK, deleteInput: {
                    tenantIdOrKey: tenantIdOrKey,
                    siteIdOrName: selectedSite.id,
                    systemLinkIdOrAlias: systemLinkToBeDeleted.id,
                }, queryToRefetch: "getSystemLinks", resetItemToBeDeleted: setSystemLinkToBeDeleted, deleteReturnPropertyName: "deleteSystemLink" })), _jsxs("div", { className: rootStyles.wrapper, children: [_jsx(Title1, { className: rootStyles.pageTitle, children: "System Links" }), _jsx("div", { className: styles.container, children: systemLinks.length === 0 ? (_jsx(NoSystemLinks, {})) : (systemLinks
                            .slice()
                            .sort((systemLinkA, systemLinkB) => systemLinkA.alias.localeCompare(systemLinkB.alias))
                            .map((systemLink) => (_jsx("div", { className: styles.cardContainer, "data-testid": "system-link-card", children: _jsx(Card, { headerText: systemLink.alias, onClickHeader: () => {
                                    setSystemLinkInDetailView(systemLink);
                                    setOpenDetailView(true);
                                }, menuItems: menuCard(systemLink), children: _jsx(SystemLinkInfo, { systemName: systemLink.systemName, systemId: systemLink.systemId, systems: systems, tenantIdOrKey: tenantIdOrKey, dispatch: dispatch }) }, `Card-${systemLink.id}`) }, systemLink.id)))) })] })] }));
};
export default SystemLinksCards;
