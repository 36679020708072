import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useFluent, useScrollbarWidth, } from "@fluentui/react-components";
import { DataGridBody, DataGrid, DataGridRow, DataGridHeader, DataGridCell, DataGridHeaderCell, } from "@fluentui-contrib/react-data-grid-react-window";
const renderRow = ({ item, rowId }, style) => (_jsx(DataGridRow, { style: style, children: ({ renderCell }) => _jsx(DataGridCell, { children: renderCell(item) }) }, rowId));
const JobList = ({ columns, items, onSelectionChange, parentRef, }) => {
    const { targetDocument } = useFluent();
    const scrollbarWidth = useScrollbarWidth({ targetDocument });
    const [parentOffset, setParentOffset] = useState({
        width: 0,
        height: 0,
    });
    const [sortState, setSortState] = useState({
        sortColumn: "name",
        sortDirection: "ascending",
    });
    const onSortChange = (e, nextSortState) => {
        setSortState(nextSortState);
    };
    useEffect(() => {
        const handleResize = () => {
            if (parentRef.current) {
                setParentOffset({
                    width: parentRef.current.offsetWidth - 15 - (scrollbarWidth !== null && scrollbarWidth !== void 0 ? scrollbarWidth : 0),
                    height: parentRef.current.offsetHeight - 50,
                });
            }
        };
        handleResize();
        const resizeObserver = new ResizeObserver(() => {
            handleResize();
        });
        resizeObserver.observe(parentRef.current);
        // Clean up
        return () => resizeObserver.disconnect();
    }, []);
    return (_jsxs(DataGrid
    // To need to re-render DataGrid in this way DataGrid is going to have
    // recalculate the columns width, for this reason was added the parent
    // width as a key to the component.
    , { items: items, columns: columns, focusMode: "cell", resizableColumns: true, sortable: true, sortState: sortState, onSortChange: onSortChange, selectionMode: "single", onSelectionChange: (_, data) => {
            const iterator = data.selectedItems.values();
            if (onSelectionChange) {
                onSelectionChange(items[iterator.next().value]);
            }
        }, columnSizingOptions: {
            name: { defaultWidth: parentOffset.width * 0.25 },
            status: { defaultWidth: 50 },
            parameters: { defaultWidth: parentOffset.width * 0.25 },
            description: { defaultWidth: parentOffset.width * 0.33 },
            lastEdit: { defaultWidth: parentOffset.width * 0.16, minWidth: 150 },
            event: { idealWidth: 250 },
            active: { defaultWidth: 50, minWidth: 50 },
        }, children: [_jsx(DataGridHeader, { children: _jsx(DataGridRow, { children: ({ renderHeaderCell }) => (_jsx(DataGridHeaderCell, { children: renderHeaderCell() })) }) }), _jsx(DataGridBody, { itemSize: 45, height: parentOffset.height, children: renderRow })] }, parentOffset.width));
};
export default JobList;
