import { jsx as _jsx } from "react/jsx-runtime";
import { TableCellLayout, makeStyles, mergeClasses, tokens, } from "@fluentui/react-components";
import { CheckmarkCircle20Regular, ClockAlarm20Regular, Flash20Regular, LayerDiagonal20Regular, Play20Regular, Warning20Regular, } from "@fluentui/react-icons";
import { createColumn } from "../../../components/listUtils";
const iconNameDictionary = {
    running: Play20Regular,
    succeeded: CheckmarkCircle20Regular,
    queued: LayerDiagonal20Regular,
    error: Warning20Regular,
    successive: ClockAlarm20Regular,
    userLog: Flash20Regular,
};
const useStyles = makeStyles({
    padding: {
        paddingLeft: "10px",
    },
    running: {
        color: tokens.colorBrandForegroundLink,
    },
    succeeded: {
        color: tokens.colorPaletteGreenForeground1,
    },
    queued: {
        color: tokens.colorBrandForegroundLink,
    },
    error: {
        color: tokens.colorPaletteRedForeground1,
    },
    successive: {
        color: tokens.colorBrandForegroundLink,
    },
    userLog: {
        color: tokens.colorBrandForegroundLink,
    },
});
const JobIconColumn = ({ jobIcon, showText, }) => {
    const styles = useStyles();
    const Icon = iconNameDictionary[jobIcon];
    return (_jsx(Icon, { className: mergeClasses(!showText && styles.padding, styles[jobIcon]) }));
};
const createIconColumn = (columnId, headerText, iconMap, showText) => {
    const mapIconName = (value) => (iconMap ? iconMap(value) : value);
    const iconColumnGenerator = (item, columnId) => (_jsx(TableCellLayout, { "data-testid": "icon-cell-item", media: _jsx(JobIconColumn, { jobIcon: mapIconName(item[columnId]), showText: !!showText }), children: showText && _jsx("span", { children: item[columnId] }) }));
    return createColumn(columnId, headerText, iconColumnGenerator, true);
};
export { createIconColumn };
