import { useState, useRef, useEffect } from "react";
function useElementResize() {
    const ref = useRef(null);
    const [size, setSize] = useState({ width: 0, height: 0 });
    const [overflow, setOverflow] = useState(false);
    useEffect(() => {
        const observeTarget = ref.current;
        if (!observeTarget)
            return;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                setSize({
                    width: entry.contentRect.width,
                    height: entry.contentRect.height,
                });
            });
        });
        setOverflow(observeTarget.scrollWidth > observeTarget.clientWidth ||
            observeTarget.scrollHeight > observeTarget.clientHeight);
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref.current]);
    return { ref, size, overflow };
}
export default useElementResize;
