import { jsx as _jsx } from "react/jsx-runtime";
import { Button, makeStyles } from "@fluentui/react-components";
import { Add20Regular } from "@fluentui/react-icons";
const useStyles = makeStyles({
    button: {
        paddingLeft: "0",
    },
});
const AddButton = ({ onClick }) => {
    const styles = useStyles();
    return (_jsx(Button, { className: styles.button, appearance: "primary", size: "medium", icon: _jsx(Add20Regular, {}), onClick: onClick, children: "Add" }));
};
export default AddButton;
