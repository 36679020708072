const ErrorMessages = {
    invalidTenant: "Please select a valid tenant",
    nonAuthenticated: "Please sign in",
    invalidRoute: "Please insert a valid route",
    unexistingTenant: "Tenant does not exist",
    unknownError: "Unknown Error",
    unexpectedError: "Something unexpected has happened",
};
const getErrorMessage = (errorMessage) => {
    if (errorMessage.status && errorMessage.status >= 500) {
        console.error(JSON.stringify(errorMessage));
        return ErrorMessages.unexpectedError;
    }
    if (!(errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.message)) {
        return ErrorMessages.unknownError;
    }
    return errorMessage.message;
};
const mapApolloErrorToErrorMessage = (apolloError) => {
    var _a, _b;
    let statusCode = undefined;
    let message = apolloError.message;
    if (apolloError.networkError) {
        const networkError = apolloError.networkError;
        if (networkError.statusCode) {
            statusCode = networkError.statusCode;
        }
        if ((_b = (_a = networkError.result) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b.length) {
            message = networkError.result.errors[0].message;
        }
    }
    return { message, status: statusCode };
};
export { ErrorMessages, getErrorMessage, mapApolloErrorToErrorMessage };
