/* istanbul ignore file */
import { combineReducers } from "@reduxjs/toolkit";
import routeReducer from "../redux/routeSlice";
import appStateReducer from "./appStateSlice";
import jobsReducer from "./jobsSlice";
import systemsFilterReducer from "./systemsFilterSlice";
import tenantsReducer from "./tenantsSlice";
import userInfoReducer from "./userInfoSlice";
const rootReducer = combineReducers({
    appState: appStateReducer,
    jobs: jobsReducer,
    routePage: routeReducer,
    systemsFilter: systemsFilterReducer,
    tenant: tenantsReducer,
    userInfo: userInfoReducer,
});
export default rootReducer;
