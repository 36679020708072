import { createSlice } from "@reduxjs/toolkit";
export const initialState = {
    id: "",
    key: "",
    name: "",
    azureTenants: [],
    sites: [],
};
const tenant = createSlice({
    name: "tenant",
    initialState,
    reducers: {
        addSite(state, action) {
            state.sites = [...(state.sites || []), { ...action.payload, isNew: true }];
        },
        setTenant(state, action) {
            state.id = action.payload.id;
            state.key = action.payload.key;
            state.name = action.payload.name;
            state.azureTenants = action.payload.azureTenants;
            state.sites = action.payload.sites;
        },
        resetTenant(state) {
            state.id = initialState.id;
            state.key = initialState.key;
            state.name = initialState.name;
            state.azureTenants = initialState.azureTenants;
            state.sites = initialState.sites;
        },
        updateSites(state, action) {
            state.sites = action.payload;
        },
        updateSite(state, action) {
            if (state.sites) {
                state.sites = state.sites.map((site) => {
                    if (site.id === action.payload.id) {
                        return {
                            ...site,
                            ...action.payload,
                        };
                    }
                    return site;
                });
            }
        },
    },
});
export const { addSite, setTenant, resetTenant, updateSites, updateSite } = tenant.actions;
export default tenant.reducer;
