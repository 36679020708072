import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, makeStyles, shorthands } from "@fluentui/react-components";
const useStyles = makeStyles({
    text: {
        fontWeight: "700",
        display: "flex",
    },
    avatar: {
        marginRight: "0.5rem",
    },
    ellipsis: {
        ...shorthands.overflow("hidden"),
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
});
const ColumnHeader = ({ text, badge, width, }) => {
    const styles = useStyles();
    return badge ? (_jsxs("div", { className: styles.text, style: { width: width }, children: [_jsx(Avatar, { "data-testid": "header-badge", className: styles.avatar, shape: "square", size: 20, color: "colorful", name: text }), _jsx("span", { className: styles.ellipsis, children: text })] })) : (_jsx("div", { className: styles.text, children: text }));
};
export default ColumnHeader;
