const formatDate = (date) => {
    return new Date(date).toLocaleString("en", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
};
export { formatDate };
