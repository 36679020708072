import { useEffect } from "react";
var UseDragDropEvents;
(function (UseDragDropEvents) {
    UseDragDropEvents[UseDragDropEvents["dragstart"] = 0] = "dragstart";
    UseDragDropEvents[UseDragDropEvents["dragenter"] = 1] = "dragenter";
    UseDragDropEvents[UseDragDropEvents["dragleave"] = 2] = "dragleave";
    UseDragDropEvents[UseDragDropEvents["dragover"] = 3] = "dragover";
    UseDragDropEvents[UseDragDropEvents["dragend"] = 4] = "dragend";
    UseDragDropEvents[UseDragDropEvents["drop"] = 5] = "drop";
})(UseDragDropEvents || (UseDragDropEvents = {}));
export const useDragDrop = (events, targetOnDragDrop) => {
    let target = targetOnDragDrop;
    const handlerFactory = (handlerFunction) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        handlerFunction && handlerFunction(e);
    };
    const handlers = {
        dragstart: handlerFactory(),
        dragenter: handlerFactory(),
        dragend: handlerFactory(),
        dragleave: handlerFactory(),
        dragover: handlerFactory(),
        drop: handlerFactory(),
    };
    events.forEach((event) => {
        handlers[UseDragDropEvents[event.type]] = handlerFactory(event.handler);
    });
    const setTarget = (targetOnDragDrop) => {
        target = targetOnDragDrop;
        suscribe();
    };
    const suscribe = () => {
        target === null || target === void 0 ? void 0 : target.addEventListener("dragstart", handlers.dragstart);
        target === null || target === void 0 ? void 0 : target.addEventListener("dragenter", handlers.dragenter);
        target === null || target === void 0 ? void 0 : target.addEventListener("dragleave", handlers.dragleave);
        target === null || target === void 0 ? void 0 : target.addEventListener("dragover", handlers.dragover);
        target === null || target === void 0 ? void 0 : target.addEventListener("dragend", handlers.dragend);
        target === null || target === void 0 ? void 0 : target.addEventListener("drop", handlers.drop);
    };
    const unsuscribe = () => {
        target === null || target === void 0 ? void 0 : target.removeEventListener("dragstart", handlers.dragstart);
        target === null || target === void 0 ? void 0 : target.removeEventListener("dragenter", handlers.dragenter);
        target === null || target === void 0 ? void 0 : target.removeEventListener("dragleave", handlers.dragleave);
        target === null || target === void 0 ? void 0 : target.removeEventListener("dragover", handlers.dragover);
        target === null || target === void 0 ? void 0 : target.removeEventListener("dragend", handlers.dragend);
        target === null || target === void 0 ? void 0 : target.removeEventListener("drop", handlers.drop);
    };
    useEffect(() => {
        suscribe();
        return () => {
            unsuscribe();
        };
    });
    return [setTarget, suscribe, unsuscribe];
};
export default useDragDrop;
export { UseDragDropEvents };
