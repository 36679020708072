import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";
import { details as useSharedStyles } from "../../../shared/sharedStyles";
import Description from "./Description";
import SystemLinks from "./SystemLinks";
const useStyles = makeStyles({
    root: {
        ...shorthands.padding("1rem"),
    },
});
const SiteDetail = ({ site }) => {
    const sharedStyles = useSharedStyles();
    const styles = useStyles();
    return (_jsxs("div", { className: mergeClasses(styles.root, sharedStyles.root), children: [_jsx(Description, { description: site.description }), _jsx(SystemLinks, { systemLinks: site.systemLinks })] }));
};
export default SiteDetail;
