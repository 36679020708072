import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from "@apollo/client";
import { Spinner } from "@fluentui/react-components";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorDisplay } from "../../../components/Error";
import { Details } from "../../../library/src";
import { ErrorMessages } from "../../Error/errorMessages";
import GatewayDetail from "./Details";
import GatewaysCards from "./GatewaysCards";
import { rootPage as useStyles } from "../../shared/sharedStyles";
const query = gql `
  query getGateways($tenantIdOrKey: String!) {
    tenant(tenantIdOrKey: $tenantIdOrKey) {
      systems {
        id
        name
        viaGateway
      }
      gateways {
        id
        version
        state
        processId
        createdAt
        createdById
        createdByDisplayName
        lastUpdated
        machineName
      }
      id
      key
    }
  }
`;
const nullGateway = {
    id: "",
    version: "",
    state: "",
    processId: "",
    createdAt: "",
    createdById: "",
    createdByDisplayName: "",
    lastUpdated: "",
    machineName: "",
};
const Gateways = () => {
    const styles = useStyles();
    const { tenantIdOrKey } = useParams();
    const [openDetailsView, setOpenDetailsView] = useState(false);
    const [gatewayToShowInDetailsView, setGatewayToShowInDetailsView] = useState(nullGateway);
    const { loading, error, data } = useQuery(query, {
        variables: { tenantIdOrKey: tenantIdOrKey },
    });
    let content;
    if (loading) {
        content = _jsx(Spinner, { label: "Loading...", size: "huge", className: styles.spinner });
    }
    else if (error) {
        content = _jsx(ErrorDisplay, { error: { message: error === null || error === void 0 ? void 0 : error.message } });
    }
    else if (data.tenant === null) {
        content = _jsx(ErrorDisplay, { error: { message: ErrorMessages.invalidTenant } });
    }
    else {
        content = (_jsx(GatewaysCards, { tenantIdOrKey: data.tenant.key, gateways: data.tenant.gateways, systems: data.tenant.systems, openDetailsView: setOpenDetailsView, setGatewayToShowInDetailsView: setGatewayToShowInDetailsView }));
    }
    return (_jsxs("div", { className: styles.root, "data-testid": "card-gateway", children: [_jsx(Details, { isOpen: openDetailsView, title: `Details ${gatewayToShowInDetailsView.machineName}`, onDismiss: () => {
                    setOpenDetailsView(false);
                }, children: _jsx(Details.Body, { children: _jsx(GatewayDetail, { gateway: gatewayToShowInDetailsView }) }) }), _jsx("div", { className: styles.body, children: content })] }));
};
export default Gateways;
