var _a;
import { EventType, PublicClientApplication, InteractionRequiredAuthError, } from "@azure/msal-browser";
import { apiConfig, msalConfig } from "./authConfig";
export const msalInstance = new PublicClientApplication(msalConfig);
(async function (msalInstance) {
    // We are using this IIFE function to avoid the jest error :
    // TS1378: Top-level 'await' expressions are only allowed when the 'module' option is set to 'es2022', 'esnext', 'system', 'node16', or 'nodenext', and the 'target' option is set to 'es2017' or higher.
    // This issue is reported to the ts-jest repositori: https://github.com/kulshekhar/ts-jest/issues/4223
    // When this issue will be solved we can remove the IIFE function and add the code "await msalInstance.initialize();" after the msalInstance const creation
    await msalInstance.initialize();
})(msalInstance);
// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && ((_a = msalInstance.getAllAccounts()) === null || _a === void 0 ? void 0 : _a.length) > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}
// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && (event === null || event === void 0 ? void 0 : event.payload)) {
        const authenticationResult = event.payload;
        msalInstance.setActiveAccount(authenticationResult.account);
    }
});
const acquireToken = async (request) => {
    let account = msalInstance.getActiveAccount();
    if (!account) {
        return Promise.reject("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    const tokenRequest = {
        ...request,
        account,
    };
    return msalInstance.acquireTokenSilent(tokenRequest).catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
            await msalInstance.acquireTokenRedirect(tokenRequest);
        }
        //If the above condition is true, this exception will never be thrown, because the page will be redirected to the MS for the authentication.
        throw error;
    });
};
export const acquireApiToken = async () => {
    return acquireToken({ scopes: apiConfig.scopes });
};
