import { LOAD_FILE_ERRORS, fetchRetryAttemptCount, fetchRetryMaxDelay, fetchRetryMinDelay } from "./api";
import { wait } from "./apiUtils";
// Define custom error classes
export class ClientError extends Error {
    constructor({ message, status }) {
        super(message);
        this.name = "4xx Error";
        this.status = status;
    }
}
export class ServerError extends Error {
    constructor({ message, status }) {
        super(message);
        this.name = "5xx Error";
        this.status = status;
    }
}
// Define a function to fetch with retry logic
export const fetchWithRetry = async (url, config, tries = fetchRetryAttemptCount, delay) => {
    try {
        const response = await fetch(url, config);
        if (response.ok) {
            return response;
        }
        if (response.status >= 400 && response.status < 499) {
            if (response.status === 401) {
                throw new ClientError({ message: "Not authorized", status: response.status });
            }
            else if (response.status === 404) {
                throw new ClientError({ message: `Resource doesn't exist`, status: response.status });
            }
            else {
                const responseJson = await response.json();
                if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.title) {
                    throw new ClientError({ message: responseJson.title, status: response.status });
                }
                else {
                    throw new ClientError({ message: `Client error: ${response.status}`, status: response.status });
                }
            }
        }
        else if (response.status >= 499) {
            throw new ServerError({ message: `Server error: ${response.status}`, status: response.status });
        }
        else {
            throw new Error(`Unexpected response status: ${response.status}`);
        }
    }
    catch (error) {
        if (error.message === "Failed to fetch") {
            throw new ClientError({ message: "Failed to fetch", status: LOAD_FILE_ERRORS.FILE_UPDATED });
        }
        else if (error instanceof ClientError || tries < 1) {
            throw error;
        }
        else {
            await wait(delay !== null && delay !== void 0 ? delay : Math.floor(Math.random() * (fetchRetryMaxDelay - fetchRetryMinDelay) + fetchRetryMinDelay));
            return await fetchWithRetry(url, config, tries - 1);
        }
    }
};
