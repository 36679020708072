import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef } from "react";
import { Avatar, Dropdown, makeStyles, Option, tokens, shorthands, } from "@fluentui/react-components";
import { EllipsisTooltipText } from "../EllipsisTooltipText";
const useStyles = makeStyles({
    root: {
        backgroundColor: tokens.colorNeutralBackground2,
        borderBottomStyle: "none",
        "&::after": {
            borderBottomStyle: "none",
        },
        "> button": {
            "&:focus": {
                borderBottomStyle: "none",
            },
        },
    },
    notDisplay: {
        display: "none",
    },
    backgroundAllSites: {
        backgroundColor: tokens.colorNeutralForegroundDisabled,
    },
    option: {
        "> span": {
            order: "2",
            flexGrow: "1",
            display: "flex",
            flexDirection: "row-reverse",
        },
    },
    text: {
        fontWeight: "400",
        display: "flex",
        alignItems: "center",
        width: "14.063rem",
    },
    ellipsis: {
        textOverflow: "ellipsis",
        ...shorthands.overflow("hidden"),
        whiteSpace: "nowrap",
        width: "90%",
    },
    avatar: {
        marginRight: "0.5rem",
    },
});
const dropDownButtonContents = {};
const SitesDropdown = ({ options, defaultValueId, onOptionSelect, showAllSitesOption = false, selectAllSitesByDefault = false, }) => {
    const styles = useStyles();
    const dropDownButton = useRef(null);
    const dropDownButtonContent = useRef(null);
    const allSitesOption = {
        id: "00000000-0000-0000-0000-000000000000",
        text: "All Sites",
    };
    // Validate props
    if (!defaultValueId && !selectAllSitesByDefault) {
        throw new Error("Invalid configuration: Either 'defaultValueId' must be specified or 'selectAllSitesByDefault' must be set to true.");
    }
    // Ensure 'All Sites' option is shown if it's the default one
    showAllSitesOption = showAllSitesOption || selectAllSitesByDefault;
    const selectedDefaultOption = selectAllSitesByDefault
        ? allSitesOption
        : options.find((option) => option.id === defaultValueId);
    if (!selectedDefaultOption) {
        throw new Error("Invalid defaultValueId.");
    }
    const handleOptionSelect = useCallback((_, data) => {
        if (dropDownButton.current &&
            dropDownButton.current.firstChild &&
            data.optionValue)
            dropDownButton.current.replaceChild(dropDownButtonContents[data.optionValue], dropDownButton.current.firstChild);
        onOptionSelect(data.optionValue);
    }, [onOptionSelect]);
    const handleBadgeAndNameClick = (event) => {
        let targetElement = event.target;
        while (targetElement &&
            targetElement.tagName !== "BUTTON" &&
            targetElement.role !== "option") {
            targetElement = targetElement.parentElement;
        }
        if (targetElement && targetElement.tagName === "BUTTON") {
            targetElement.click();
        }
    };
    const renderOptionBadgeAndName = (option, isAllSitesOption = false) => (_jsxs("div", { className: styles.text, "data-id": option.id, onClick: handleBadgeAndNameClick, children: [_jsx(Avatar, { className: styles.avatar, shape: "square", size: 24, color: isAllSitesOption ? "neutral" : "colorful", name: option.text }), _jsx(EllipsisTooltipText, { text: option.text, width: "90%", oneLine: true })] }, option.id));
    const renderAllOptions = () => {
        const optionsElements = options.map((option) => renderOptionBadgeAndName(option));
        if (showAllSitesOption) {
            return [
                renderOptionBadgeAndName(allSitesOption, true),
                ...optionsElements,
            ];
        }
        return optionsElements;
    };
    useEffect(() => {
        if (dropDownButtonContent.current) {
            dropDownButtonContent.current
                .querySelectorAll("[data-id]")
                .forEach((node) => {
                const id = node.getAttribute("data-id");
                if (id) {
                    dropDownButtonContents[id] = node;
                }
            });
        }
        if (dropDownButton.current && dropDownButton.current.firstChild) {
            dropDownButton.current.replaceChild(dropDownButtonContents[selectedDefaultOption.id], dropDownButton.current.firstChild);
        }
    }, []);
    return (_jsxs("div", { children: [_jsx("div", { ref: dropDownButtonContent, className: styles.notDisplay, children: renderAllOptions() }), _jsxs(Dropdown, { ref: dropDownButton, className: styles.root, defaultValue: selectedDefaultOption.id, "data-testid": "sites-dropdown", defaultSelectedOptions: [selectedDefaultOption.id], appearance: "underline", onOptionSelect: handleOptionSelect, children: [showAllSitesOption && (_jsx(Option, { text: allSitesOption.text, value: allSitesOption.id, "data-testid": allSitesOption.id, className: styles.option, children: renderOptionBadgeAndName(allSitesOption, true) }, allSitesOption.id)), options.map((option) => (_jsx(Option, { text: option.text, value: option.id, "data-testid": option.id, className: styles.option, children: renderOptionBadgeAndName(option) }, option.id)))] })] }));
};
export default SitesDropdown;
