import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Subtitle2, makeStyles } from "@fluentui/react-components";
import emptyJobsImage from "./emptyJobList.svg";
const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
    },
    noJobs: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
});
const NoSystems = () => {
    const styles = useStyles();
    return (_jsx("div", { className: styles.root, children: _jsxs("div", { className: styles.noJobs, "data-testid": "job-list-empty", children: [_jsx("img", { src: emptyJobsImage, alt: "No defined jobs" }), _jsx(Subtitle2, { children: "You have no Job Definitions yet." })] }) }));
};
export default NoSystems;
