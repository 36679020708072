import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles, mergeClasses, tokens } from "@fluentui/react-components";
import { ErrorCircleRegular, InfoRegular, WarningRegular, } from "@fluentui/react-icons";
import { ModalTypeEnum } from "./Modal";
const useStyles = makeStyles({
    icon: {
        fontSize: "4em",
        paddingRight: "0.1em",
    },
    iconInfo: {
        color: tokens.colorPaletteBlueBorderActive,
    },
    iconWarning: {
        color: tokens.colorPaletteDarkOrangeBorderActive,
    },
    iconError: {
        color: tokens.colorPaletteDarkRedBorderActive,
    },
    body: {
        display: "flex",
        alignItems: "center",
    },
});
const ModalBody = ({ modalType, bodyText, children }) => {
    const styles = useStyles();
    const iconNode = () => {
        let iconName = "";
        let icon = _jsx(_Fragment, {});
        switch (modalType) {
            case ModalTypeEnum.Info:
                icon = (_jsx(InfoRegular, { className: mergeClasses(styles.icon, styles.iconInfo) }));
                iconName = "info";
                break;
            case ModalTypeEnum.Warning:
                icon = (_jsx(WarningRegular, { className: mergeClasses(styles.icon, styles.iconWarning) }));
                iconName = "warning";
                break;
            case ModalTypeEnum.Error:
                icon = (_jsx(ErrorCircleRegular, { className: mergeClasses(styles.icon, styles.iconError) }));
                iconName = "error";
                break;
        }
        return _jsx("div", { "data-testid": `modal-body-icon-${iconName}`, children: icon });
    };
    if (modalType === ModalTypeEnum.Normal) {
        return _jsx("div", { "data-testid": "modal-body", children: bodyText !== null && bodyText !== void 0 ? bodyText : children });
    }
    else {
        return (_jsxs("div", { className: styles.body, "data-testid": "modal-body", children: [iconNode(), _jsx("div", { children: bodyText !== null && bodyText !== void 0 ? bodyText : children })] }));
    }
};
export default ModalBody;
