import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { MenuItem, Tooltip, makeStyles, mergeClasses, tokens, } from "@fluentui/react-components";
import { bundleIcon } from "@fluentui/react-icons";
const useStyles = makeStyles({
    root: {
        fontSize: "14px",
        backgroundColor: tokens.colorNeutralBackground2,
        paddingBottom: "8px",
    },
    active: {
        backgroundColor: tokens.colorNeutralBackground3Hover,
        fontWeight: "700",
    },
    activeIcon: {
        color: "red;",
    },
});
const NavItems = ({ groups, onLinkClick, selectedKey, onlyIcons = false, }) => {
    const styles = useStyles();
    const mapLinks = () => groups.map((link) => {
        let name = undefined;
        let icon;
        if (link.key === selectedKey) {
            icon = (_jsx(link.iconActive, { "data-testid": `menu-item-icon-active-${link.iconActive.displayName}` }));
        }
        else {
            const IconBundled = bundleIcon(link.iconActive, link.icon);
            icon = (_jsx(IconBundled, { "data-testid": `menu-item-icon-${link.icon.displayName}` }));
        }
        return onlyIcons ? (_jsx(Tooltip, { content: link.name, withArrow: true, positioning: "after", relationship: "label", children: _jsx(MenuItem, { "data-testid": `nav-menu-item-${link.name}`, className: mergeClasses(styles.root, link.key === selectedKey && styles.active), icon: icon, disabled: link.disabled, onClick: (e) => {
                    e.preventDefault();
                    onLinkClick(link);
                } }) }, link.key)) : (_jsx(MenuItem, { "data-testid": `nav-menu-item-${link.name}`, className: mergeClasses(styles.root, link.key === selectedKey && styles.active), icon: icon, disabled: link.disabled, onClick: (e) => {
                e.preventDefault();
                onLinkClick(link);
            }, children: link.name }, link.key));
    });
    return _jsx(_Fragment, { children: mapLinks() });
};
export default NavItems;
