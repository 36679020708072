import { createSlice } from "@reduxjs/toolkit";
import { uploadJobs as uploadJobsFromApi } from "../api/api";
import { TabOptions } from "../components/Jobs/JobUtils";
export const initialState = {
    filesToUpload: [],
    sending: false,
    error: null,
    success: false,
    tab: TabOptions.JOBS,
    selectedJob: {
        jobDefinitionIdOrName: "",
        parameters: [],
    },
};
const jobsSlice = createSlice({
    name: "jobs",
    initialState,
    reducers: {
        initError(state) {
            state.error = null;
        },
        initSuccess(state) {
            state.success = false;
        },
        setFilesToUpload(state, action) {
            state.filesToUpload = [...action.payload];
            state.sending = false;
            state.error = null;
        },
        getActionStart(state) {
            state.sending = true;
            state.error = null;
            state.success = false;
        },
        uploadSuccess(state) {
            state.sending = false;
            state.error = null;
            state.success = true;
        },
        uploadFailure(state, action) {
            state.sending = false;
            state.error = action.payload;
        },
        setTab(state, action) {
            state.tab = action.payload;
        },
        setSelectedJob(state, action) {
            state.selectedJob = action.payload;
        },
    },
});
const { initError, initSuccess, setFilesToUpload, getActionStart, uploadFailure, uploadSuccess, setTab, setSelectedJob, } = jobsSlice.actions;
const uploadJobs = () => async (dispatch, getState) => {
    try {
        dispatch(getActionStart());
        await uploadJobsFromApi(getState().jobs.filesToUpload, getState().tenant.id, getState().appState.selectedSite.id);
        dispatch(uploadSuccess());
    }
    catch (err) {
        dispatch(uploadFailure(err));
    }
};
export default jobsSlice.reducer;
export { initError, initSuccess, setFilesToUpload, getActionStart, uploadFailure, uploadSuccess, setTab, setSelectedJob, uploadJobs, };
