import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from "@apollo/client";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { rootPage as useStyles } from "../../shared/sharedStyles";
import { PrincipalType } from "../../../types";
import { Spinner } from "@fluentui/react-components";
import { ErrorDisplay, ErrorMessages } from "../../Error";
import { Details } from "../../../library/src";
import TenantAdminCards from "./TenantAdminCards";
import { TenantDetail } from "./Details";
const QUERY = gql `
  query principals($tenantIdOrKey: String!) {
    principals(tenantIdOrKey: $tenantIdOrKey) {
      displayName
      email
      id
      principalType
      roleAssignments {
        principalId
        roleId
        siteId
        tenantId
      }
    }
  }
`;
const nullPrincipal = {
    displayName: "",
    email: "",
    id: "",
    principalType: PrincipalType.USER,
    roleAssignments: [],
};
const Tenant = () => {
    const styles = useStyles();
    const { tenantIdOrKey } = useParams();
    const [openDetailsView, setOpenDetailsView] = useState(false);
    const [principalToShowInDetailsView, setPrincipalToShowInDetailsView] = useState(nullPrincipal);
    const openDetailsViewAndSetItem = (principal) => {
        setOpenDetailsView(true);
        setPrincipalToShowInDetailsView({ ...principal });
    };
    const { loading, error, data } = useQuery(QUERY, {
        variables: { tenantIdOrKey: tenantIdOrKey },
    });
    let content;
    if (loading) {
        content = _jsx(Spinner, { label: "Loading...", size: "huge", className: styles.spinner });
    }
    else if (error) {
        content = _jsx(ErrorDisplay, { error: { message: error === null || error === void 0 ? void 0 : error.message } });
    }
    else if (data.tenant === null) {
        content = _jsx(ErrorDisplay, { error: { message: ErrorMessages.invalidTenant } });
    }
    else {
        const principalsOrderByDisplayName = [...data.principals].sort((a, b) => {
            if (a.displayName === "")
                return 1;
            if (b.displayName === "")
                return -1;
            return a.displayName.localeCompare(b.displayName);
        });
        content = (_jsx(TenantAdminCards, { principals: principalsOrderByDisplayName, openDetailsView: openDetailsViewAndSetItem }));
    }
    return (_jsxs("div", { className: styles.root, children: [_jsx(Details, { isOpen: openDetailsView, onDismiss: () => setOpenDetailsView(false), title: `Details ${principalToShowInDetailsView.displayName}`, children: _jsx(Details.Body, { children: _jsx(TenantDetail, { principal: principalToShowInDetailsView }) }) }), _jsx("div", { className: styles.body, children: content })] }));
};
export default Tenant;
