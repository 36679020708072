import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { AppStateError } from "../Error";
import { Jobs } from "../Jobs";
import { NavPane } from "../NavPane";
import { rootPage as useStyles } from "../shared/sharedStyles";
import { SystemLinks } from "../SystemLinks";
import { WelcomeName } from "./WelcomeName";
import { useUserRoles } from "../../hooks";
const Home = () => {
    const styles = useStyles();
    const { instance, inProgress } = useMsal();
    const [name, setName] = useState("");
    const { tenantAdmin, siteAdmin, jobDesigner, consumer } = useUserRoles();
    useEffect(() => {
        var _a;
        const activeAccount = instance.getActiveAccount();
        if (inProgress === InteractionStatus.None) {
            setName((_a = activeAccount === null || activeAccount === void 0 ? void 0 : activeAccount.name) !== null && _a !== void 0 ? _a : "");
        }
    }, [instance, inProgress]);
    return (_jsxs(_Fragment, { children: [_jsx(NavPane, {}), _jsx("div", { className: styles.page, children: !tenantAdmin && !siteAdmin && !jobDesigner && !consumer ? (_jsx(AppStateError, { authorizedError: true })) : (_jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(WelcomeName, { name: name }) }), _jsx(Route, { path: "jobs", element: _jsx(Jobs, {}) }), _jsx(Route, { path: "system-links", element: _jsx(SystemLinks, {}) })] })) })] }));
};
export default Home;
