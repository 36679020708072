import { createSlice } from "@reduxjs/toolkit";
export const initialState = {
    active: true,
    applied: false,
    filter: {
        id: "",
        name: "",
        type: "",
        gatewayId: "",
        gatewayMachineName: "",
    },
};
function isFilterClear(filter) {
    return (filter.gatewayId === "" &&
        filter.gatewayMachineName === "" &&
        filter.id === "" &&
        filter.name === "" &&
        filter.type === "");
}
const systemFilterSlice = createSlice({
    name: "jobs",
    initialState,
    reducers: {
        activateFilter(state) {
            state.active = true;
        },
        applyFilter(state) {
            state.applied = true;
        },
        setFilter(state, action) {
            state.filter = { ...state.filter, ...action.payload };
        },
        setAndApplyFilter(state, action) {
            state.filter = { ...state.filter, ...action.payload };
            state.applied = !isFilterClear(state.filter);
        },
        setAndApplySystemIdFilter(state, action) {
            state.applied = true;
            state.filter = { ...initialState.filter, id: action.payload };
        },
        setAndApplySystemNameFilter(state, action) {
            state.applied = true;
            state.filter = { ...initialState.filter, name: action.payload };
        },
        setAndApplyGatewayIdFilter(state, action) {
            state.applied = true;
            state.filter = { ...initialState.filter, gatewayId: action.payload };
        },
        resetFilter(state) {
            state.applied = false;
            state.filter = { ...initialState.filter };
        },
    },
});
export const { activateFilter, applyFilter, setFilter, setAndApplyFilter, setAndApplySystemIdFilter, setAndApplySystemNameFilter, setAndApplyGatewayIdFilter, resetFilter, } = systemFilterSlice.actions;
export default systemFilterSlice.reducer;
