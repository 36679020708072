import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from "@apollo/client";
import { makeStyles, shorthands, Spinner } from "@fluentui/react-components";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Details } from "../../../library/src";
import { SystemKind } from "../../../types";
import { AddButton } from "../../AddButton";
import { ErrorDisplay, ErrorMessages } from "../../Error";
import { rootPage as useRootStyles } from "../../shared/sharedStyles";
import { SiteForm } from "./SiteForm";
import { SiteDetail } from "./Details";
import SitesCards from "./SitesCards";
import { SiteDelete } from "./Delete";
import { useUserRoles } from "../../../hooks";
const GET_SITES = gql `
  query getSites($tenantIdOrKey: String!) {
    tenant(tenantIdOrKey: $tenantIdOrKey) {
      id
      key
      sites {
        description
        id
        name
        systemLinks {
          alias
          id
          systemId
          systemName
        }
      }
      systems {
        id
        type
      }
    }
  }
`;
var DetailsViewType;
(function (DetailsViewType) {
    DetailsViewType["ADD"] = "add";
    DetailsViewType["DELETE"] = "delete";
    DetailsViewType["DETAILS"] = "details";
    DetailsViewType["EDIT"] = "edit";
})(DetailsViewType || (DetailsViewType = {}));
const nullSite = {
    description: "",
    id: "",
    name: "",
    systemLinks: [],
};
const useStyles = makeStyles({
    header: {
        height: "3rem",
        position: "absolute",
        top: "3rem",
        display: "flex",
        alignItems: "center",
        ...shorthands.padding("0", "1rem"),
    },
});
const Sites = () => {
    const rootStyles = useRootStyles();
    const styles = useStyles();
    const { tenantAdmin } = useUserRoles();
    const { tenantIdOrKey } = useParams();
    const [openDetailsView, setOpenDetailsView] = useState({
        open: false,
    });
    const [siteToShowInDetailsView, setSiteToShowInDetailsView] = useState(nullSite);
    const openDetailsViewAndSetItem = (systems) => {
        return (site) => {
            const extendedSystemLinks = site.systemLinks.map((systemLink) => {
                var _a;
                const system = systems.find((sys) => sys.id === systemLink.systemId);
                return { ...systemLink, systemType: (_a = system === null || system === void 0 ? void 0 : system.type) !== null && _a !== void 0 ? _a : SystemKind.NULL };
            });
            setOpenDetailsView({ open: true, detail: DetailsViewType.DETAILS });
            setSiteToShowInDetailsView({ ...site, systemLinks: extendedSystemLinks });
        };
    };
    const openEditPanelAndSetItem = (site) => {
        setOpenDetailsView({ open: true, detail: DetailsViewType.EDIT });
        setSiteToShowInDetailsView({ ...site, systemLinks: [] });
    };
    const openDeletePanelAndSetItem = (site) => {
        setOpenDetailsView({ open: true, detail: DetailsViewType.DELETE });
        setSiteToShowInDetailsView({ ...site, systemLinks: [] });
    };
    const { loading, error, data } = useQuery(GET_SITES, {
        variables: { tenantIdOrKey: tenantIdOrKey },
    });
    let content;
    if (loading) {
        content = _jsx(Spinner, { label: "Loading...", size: "huge", className: rootStyles.spinner });
    }
    else if (error) {
        content = _jsx(ErrorDisplay, { error: { message: error === null || error === void 0 ? void 0 : error.message } });
    }
    else if (data.tenant === null) {
        content = _jsx(ErrorDisplay, { error: { message: ErrorMessages.invalidTenant } });
    }
    else {
        content = (_jsx(SitesCards, { sites: data.tenant.sites, openDetailsView: openDetailsViewAndSetItem(data.tenant.systems), openEditPanel: openEditPanelAndSetItem, openDeletePanel: openDeletePanelAndSetItem }));
    }
    const detailsTitle = () => {
        switch (openDetailsView.detail) {
            case DetailsViewType.DETAILS:
                return `Details ${siteToShowInDetailsView.name}`;
            case DetailsViewType.ADD:
                return "Add Site";
            case DetailsViewType.EDIT:
                return "Edit Site";
            case DetailsViewType.DELETE:
                return "Delete Site";
            default:
                return "";
        }
    };
    return (_jsxs("div", { className: rootStyles.root, children: [tenantAdmin && (_jsx("div", { className: styles.header, children: _jsx(AddButton, { onClick: () => {
                        setOpenDetailsView({ open: true, detail: DetailsViewType.ADD });
                    } }) })), _jsx(Details, { isOpen: openDetailsView.open, type: "fullBody", onDismiss: () => setOpenDetailsView({ open: false }), title: detailsTitle(), children: _jsxs(Details.Body, { children: [openDetailsView.detail === DetailsViewType.DETAILS && _jsx(SiteDetail, { site: siteToShowInDetailsView }), openDetailsView.detail === DetailsViewType.ADD && (_jsx(SiteForm, { onCancel: () => {
                                setOpenDetailsView({ open: false });
                            }, type: "add" })), openDetailsView.detail === DetailsViewType.EDIT && (_jsx(SiteForm, { onCancel: () => {
                                setOpenDetailsView({ open: false });
                            }, type: "edit", editName: siteToShowInDetailsView.name, editDescription: siteToShowInDetailsView.description })), openDetailsView.detail === DetailsViewType.DELETE && (_jsx(SiteDelete, { site: siteToShowInDetailsView, onCancel: () => {
                                setOpenDetailsView({ open: false });
                            } }))] }) }), _jsx("div", { className: rootStyles.body, children: content })] }));
};
export default Sites;
