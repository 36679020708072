import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { JobList as JobListTable, createLinkColumn, createTextColumn, createTimeColumn, createParameterColumn, } from "../../library/src";
import { Card, makeStyles, shorthands, Spinner } from "@fluentui/react-components";
import { setSelectedJob } from "../../redux/jobsSlice";
import JobListEmpty from "./JobListEmpty";
import { ErrorDisplay } from "../Error";
import { useParams } from "react-router-dom";
const QUERY = gql `
  query jobDefinitions($tenantIdOrKey: String!, $siteIdOrName: String!) {
    jobDefinitions(tenantIdOrKey: $tenantIdOrKey, siteIdOrName: $siteIdOrName) {
      description
      id
      lastUpdated
      name
      parameters {
        name
        type
        description
      }
    }
  }
`;
const useStyles = makeStyles({
    card: {
        height: "calc(100vh - 19rem)",
        overflowY: "hidden",
    },
    spinner: {
        ...shorthands.margin("5%"),
    },
    noJobs: {
        paddingTop: "4rem",
    },
    jobListContainer: {
        ...shorthands.margin("0, 1.2rem"),
    },
});
const convertToJobItems = (jobs) => {
    return jobs.map((job) => {
        var _a, _b;
        return {
            name: job.name,
            description: job.description,
            parameters: (_a = job.parameters) !== null && _a !== void 0 ? _a : [],
            lastEdit: new Date((_b = job.lastUpdated) !== null && _b !== void 0 ? _b : ""),
        };
    });
};
const jobListColumns = [
    createLinkColumn("name", "Job"),
    createTextColumn("description", "Description"),
    createParameterColumn("parameters", "Parameters"),
    createTimeColumn("lastEdit", "Last Edit"),
];
const JobList = () => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const cardRef = useRef(null);
    const { tenantIdOrKey } = useParams();
    const { selectedSite } = useSelector((state) => state.appState);
    const { loading, error, data } = useQuery(QUERY, {
        variables: { tenantIdOrKey, siteIdOrName: selectedSite.id },
    });
    const onSelectionChange = (job) => {
        dispatch(setSelectedJob({ jobDefinitionIdOrName: job.name, parameters: job.parameters }));
    };
    if (loading) {
        return _jsx(Spinner, { label: "Loading...", size: "huge", className: styles.spinner });
    }
    else if (error) {
        return _jsx(ErrorDisplay, { error: { message: error === null || error === void 0 ? void 0 : error.message } });
    }
    else if (data) {
        return (_jsx(Card, { ref: cardRef, className: styles.card, children: data.jobDefinitions.length === 0 ? (_jsx(JobListEmpty, {})) : (_jsx("div", { className: styles.jobListContainer, children: _jsx(JobListTable, { columns: jobListColumns, items: convertToJobItems(data.jobDefinitions), onSelectionChange: onSelectionChange, parentRef: cardRef }) })) }));
    }
};
export default JobList;
