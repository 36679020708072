import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from "@fluentui/react-components";
import Role from "./Role";
const useStyles = makeStyles({
    container: {
        display: "flex",
    },
});
const SiteRoles = ({ roles, siteId }) => {
    const styles = useStyles();
    const sortedRoles = roles
        .filter((role) => role.siteId === siteId)
        .sort((a, b) => a.roleId - b.roleId);
    return (_jsx("div", { "data-testid": "role-cell-item", className: styles.container, children: sortedRoles.map((role) => (_jsx(Role, { roleId: role.roleId }, role.roleId))) }));
};
export default SiteRoles;
