import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Subtitle2Stronger } from "@fluentui/react-components";
import { details as useStyles } from "../../../shared/sharedStyles";
const ConnectionInformationDetails = ({ connectionInformation, }) => {
    const styles = useStyles();
    const formatSystems = connectionInformation.map((info) => {
        const secret = "*************";
        return (_jsxs("div", { children: [_jsxs("div", { className: styles.row, "data-testid": "system-details-connection", children: [_jsx("div", { className: styles.rowItemPropertyName, children: info.key }), _jsx("div", { children: info.type === "SECRET" ? secret : info.value })] }), _jsx(Divider, {})] }, info.key));
    });
    return (_jsxs("div", { className: styles.section, children: [_jsx(Subtitle2Stronger, { className: styles.sectionTitle, children: "Connection Information" }), formatSystems] }));
};
export default ConnectionInformationDetails;
