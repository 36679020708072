import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Body1, Spinner, makeStyles, mergeClasses, tokens, Toast, Toaster, ToastTitle, ToastTrigger, useId, useToastController, Link, ToastBody, Caption2Strong, Caption2, } from "@fluentui/react-components";
import { bundleIcon, Play20Regular, Play20Filled, iconFilledClassName, iconRegularClassName, } from "@fluentui/react-icons";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { EXECUTE_JOB } from "../../api/api";
import { setSelectedJob } from "../../redux/jobsSlice";
import ParameterModal from "./Parameters/ParameterModal";
const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "row",
    },
    runJob: {
        minWidth: "3.25rem",
        display: "flex",
        columnGap: "0.4rem",
        marginLeft: "0.7rem",
        cursor: "pointer",
        alignItems: "center",
        ":hover": {
            [`& .${iconFilledClassName}`]: {
                display: "inline",
            },
            [`& .${iconRegularClassName}`]: {
                display: "none",
            },
        },
    },
    disabled: {
        cursor: "not-allowed",
        color: tokens.colorNeutralForegroundDisabled,
    },
    errorToastMessage: {
        maxHeight: "6.25rem",
        overflowY: "auto",
    },
    noWrap: {
        whiteSpace: "nowrap",
    },
});
const RunIcon = bundleIcon(Play20Filled, Play20Regular);
const RunJobButton = () => {
    var _a;
    const styles = useStyles();
    const dispatch = useDispatch();
    const toasterID = useId("runJobToaster");
    const [openModal, setOpenModal] = useState(false);
    const [parameterValue, setParameterValue] = useState("");
    const { dispatchToast } = useToastController(toasterID);
    const { tenantIdOrKey } = useParams();
    const selectedSite = useSelector((state) => state.appState.selectedSite);
    const selectedJob = useSelector((state) => state.jobs.selectedJob);
    const [executeJob, { loading, error, data }] = useMutation(EXECUTE_JOB);
    useEffect(() => {
        dispatch(setSelectedJob({ jobDefinitionIdOrName: "", parameters: [] }));
    }, [dispatch]);
    useEffect(() => {
        if (data) {
            dispatchToast(_jsxs(Toast, { children: [_jsx(ToastTitle, { action: _jsx(ToastTrigger, { children: _jsx(Link, { children: "Dismiss" }) }), children: `Job "${selectedJob.jobDefinitionIdOrName}" started successfully` }), _jsxs(ToastBody, { "data-testid": "run-job-success-message", children: [_jsx("div", { children: _jsx(Caption2Strong, { children: "Execution Id:" }) }), _jsx("div", { children: _jsx(Caption2, { className: styles.noWrap, children: data.executeJob.jobExecutionId }) })] })] }), { timeout: -1, intent: "success" });
        }
        if (error) {
            dispatchToast(_jsxs(Toast, { children: [_jsx(ToastTitle, { action: _jsx(ToastTrigger, { children: _jsx(Link, { children: "Dismiss" }) }), children: `Job "${selectedJob}" run error` }), _jsx(ToastBody, { children: _jsx("div", { className: styles.errorToastMessage, children: _jsx(Caption2, { children: error.message }) }) })] }), { timeout: -1, intent: "error" });
        }
    }, [error, data, dispatchToast, dispatch]);
    const onClickRunJob = () => {
        if (!loading && tenantIdOrKey && selectedJob.jobDefinitionIdOrName) {
            if (selectedJob.parameters.length > 0) {
                setOpenModal(true);
            }
            else {
                executeJob({
                    variables: {
                        tenantIdOrKey: tenantIdOrKey,
                        siteIdOrName: selectedSite.id,
                        jobDefinitionIdOrName: selectedJob.jobDefinitionIdOrName,
                    },
                });
            }
        }
    };
    const cancelParameterModal = () => {
        setOpenModal(false);
        setParameterValue("");
    };
    const runFromParameterModal = (parameters) => {
        executeJob({
            variables: {
                tenantIdOrKey: tenantIdOrKey,
                siteIdOrName: selectedSite.id,
                jobDefinitionIdOrName: selectedJob.jobDefinitionIdOrName,
                parameters,
            },
        });
        setOpenModal(false);
        setParameterValue("");
    };
    return (_jsxs(_Fragment, { children: [openModal && (_jsx(ParameterModal, { cancelParameterModal: cancelParameterModal, runFromParameterModal: runFromParameterModal, parameterValue: parameterValue, setParameterValue: setParameterValue })), _jsxs("div", { role: "button", className: mergeClasses(styles.runJob, !selectedJob && styles.disabled), onClick: onClickRunJob, "data-testid": "run-button", children: [loading ? _jsx(Spinner, { size: "extra-tiny" }) : _jsx(RunIcon, {}), " ", _jsx(Body1, { children: `Run ${(_a = selectedJob.jobDefinitionIdOrName) !== null && _a !== void 0 ? _a : ""}` })] }), _jsx(Toaster, { toasterId: toasterID, position: "bottom-end" })] }));
};
export default RunJobButton;
