import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { DefaultPadding } from "../shared/sharedStyles";
const useStyles = makeStyles({
    logo: {
        fontSize: "0.875rem",
        color: tokens.colorNeutralBackground1,
        paddingLeft: "1rem",
        flexShrink: 2,
        ...shorthands.overflow("hidden"),
        textOverflow: "ellipsis",
        lineHeight: "1.25rem",
        whiteSpace: "nowrap",
    },
    bold: {
        fontWeight: tokens.fontWeightSemibold,
    },
    light: {
        fontWeight: tokens.fontWeightRegular,
        paddingLeft: DefaultPadding,
    },
});
const TenantName = ({ tenantName }) => {
    const styles = useStyles();
    return (_jsxs("div", { "data-testid": "logo", className: styles.logo, children: [_jsx("span", { "data-testid": "logo-bold", className: styles.bold, children: "PSLink" }), _jsx("span", { "data-testid": "logo-light", className: styles.light, children: tenantName })] }));
};
export default TenantName;
