/* istanbul ignore file */
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import obsoleteMiddleware from "./obsoleteMiddleware";
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(obsoleteMiddleware),
});
if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./rootReducer", () => {
        const newRootReducer = require("./rootReducer").default;
        store.replaceReducer(newRootReducer);
    });
}
export default store;
