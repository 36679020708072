import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Divider, makeStyles, mergeClasses, shorthands, tokens, } from "@fluentui/react-components";
import CardMenu from "./CardMenu";
import { Warning24Filled } from "@fluentui/react-icons";
import { EllipsisTooltipText } from "../EllipsisTooltipText";
const useStyles = makeStyles({
    header: {
        flexGrow: "1",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        width: "100%",
    },
    headerLine: {
        lineHeight: "3rem",
        position: "relative",
        flexGrow: "1",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    },
    headerLineText: {
        fontSize: "0.875rem",
        paddingLeft: "10px",
        display: "flex",
        alignItems: "center",
        fontWeight: "700",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        ...shorthands.overflow("hidden"),
        flexGrow: "1",
        height: "3rem",
    },
    headerLineTextWarning: {
        color: tokens.colorPaletteRedForeground1,
    },
    headerHover: {
        "&:hover": {
            cursor: "pointer",
            "&, & + div button": {
                color: tokens.colorBrandForegroundLink,
            },
        },
    },
    headerHoverWarning: {
        "&:hover": {
            "&, & + div button": {
                color: tokens.colorBrandForeground2,
            },
        },
    },
    warningIcon: {
        paddingRight: "0.5rem",
        flexShrink: 0,
    },
    dividerWarning: {
        "::before": {
            ...shorthands.borderColor(tokens.colorPaletteRedBackground2),
        },
        "::after": {
            ...shorthands.borderColor(tokens.colorPaletteRedBackground2),
        },
    },
    menu: {
        fontSize: "14px",
        letterSpacing: "0",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        minWidth: "0",
        overflowX: "hidden",
        overflowY: "hidden",
        cursor: "pointer",
        flexShrink: 0,
    },
    headerBadge: {
        marginRight: "0.5rem",
    },
});
const CardHeader = ({ headerText, headerBadge, menuItems, onClickHeader, activeLinkHeader, warning, mountModule, }) => {
    const styles = useStyles();
    return (_jsxs("div", { className: styles.header, children: [_jsxs("div", { className: styles.headerLine, children: [_jsxs("div", { className: mergeClasses(styles.headerLineText, warning && styles.headerLineTextWarning, onClickHeader && styles.headerHover, onClickHeader && warning && styles.headerHoverWarning), onClick: () => activeLinkHeader && onClickHeader && onClickHeader(), "data-testid": "card-header", children: [warning && (_jsx(Warning24Filled, { "data-testid": "card-warning-icon", className: styles.warningIcon })), headerBadge && (_jsx(Avatar, { shape: "square", color: "colorful", size: 28, className: styles.headerBadge, name: headerText })), _jsx(EllipsisTooltipText, { text: headerText, width: "inherit", oneLine: true })] }), menuItems && (_jsx("div", { className: styles.menu, children: _jsx(CardMenu, { menuItems: menuItems, warning: warning, mountModule: mountModule }) }))] }), _jsx(Divider, { className: mergeClasses(warning && styles.dividerWarning) })] }));
};
export default CardHeader;
