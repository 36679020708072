let isObsolete = false;
const obsoleteMiddleware = (store) => (next) => (action) => {
    if (action.type === "userInfo/setUserInfo") {
        isObsolete = false;
        setTimeout(() => {
            isObsolete = true;
            console.log("User info is now obsolete");
        }, 5 * 60 * 1000); // 5 minutes in milliseconds
    }
    return next(action);
};
export const getIsObsolete = () => isObsolete;
export default obsoleteMiddleware;
