import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const Time = ({ time, defaultText }) => {
    if (time === undefined) {
        return _jsx("div", { children: defaultText });
    }
    else {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const hour = time
            .toLocaleString("en", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        })
            .replace(/\s/g, "")
            .toLowerCase();
        if (time.toDateString() === today.toDateString()) {
            return _jsxs("div", { "data-testid": "time-cell-item", children: ["Today ", hour] });
        }
        else if (time.toDateString() === yesterday.toDateString()) {
            return _jsxs("div", { "data-testid": "time-cell-item", children: ["Yesterday ", hour] });
        }
        const year = String(time.getFullYear());
        const month = String(time.getMonth() + 1).padStart(2, "0");
        const day = String(time.getDate()).padStart(2, "0");
        return (_jsx("div", { "data-testid": "time-cell-item", children: `${year}-${month}-${day}, ${hour}` }));
    }
};
export default Time;
