import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles, mergeClasses, shorthands, Tooltip, } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import useElementResize from "../../hooks/useElementResize";
const useStyles = makeStyles({
    ellipsis: {
        textOverflow: "ellipsis",
        ...shorthands.overflow("hidden"),
        whiteSpace: "nowrap",
        display: "inline-block",
    },
    oneLineTooltip: {
        maxWidth: "fit-content",
    },
});
const EllipsisTooltipText = ({ text, width, oneLine = false }) => {
    const styles = useStyles();
    const [addTooltip, setAddTooltip] = useState(false);
    const { size, ref: textReference } = useElementResize();
    useEffect(() => {
        if (textReference.current) {
            const element = textReference.current;
            setAddTooltip(element.scrollWidth > element.clientWidth);
        }
    }, [size]);
    const textSpan = (_jsx("span", { ref: textReference, className: styles.ellipsis, style: { width: width }, children: text }));
    if (addTooltip) {
        return (_jsx(Tooltip, { content: {
                children: text,
                className: mergeClasses(oneLine && styles.oneLineTooltip),
            }, relationship: "description", withArrow: true, positioning: "after", children: textSpan }));
    }
    return textSpan;
};
export default EllipsisTooltipText;
