import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from "@apollo/client/core";
import { Switch, Tab, TabList, Text, Title1, mergeClasses } from "@fluentui/react-components";
import { Database20Regular, Delete20Regular, Eye20Regular, ServerMultiple20Regular } from "@fluentui/react-icons";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Filter } from "../../../library/src";
import { resetFilter } from "../../../redux/systemsFilterSlice";
import DeleteDialog from "../../shared/sharedDeleteUtils";
import { cardsBody as useStyles, deleteModal as useDeleteStyles, rootPage as useRootStyles, settingsPage as useSettingsStyles, } from "../../shared/sharedStyles";
import { getFormattedGroupName, getSystemGroups } from "../../shared/sharedSystemUtils";
import { NoSystems, SystemIcon } from "../Systems";
import SystemFilter from "./SystemFilter";
import { SystemKind } from "../../../types";
import { useUserRoles } from "../../../hooks";
const groupSystemsByType = (systems, systemKind) => {
    return systems.filter((system) => {
        return systemKind === SystemKind.ALL || systemKind === system.type;
    });
};
const SystemsCards = ({ tenantIdOrKey, systems, gateways, openDetailsView, setSystemToShowInDetailsView }) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { active, applied, filter } = useSelector((state) => state.systemsFilter);
    const [selectedGroup, setSelectedGroup] = useState(SystemKind.ALL);
    const [groupedSystems, setGroupedSystems] = useState(systems);
    const [filteredSystems, setFilteredSystems] = useState(systems);
    const [systemToBeDeleted, setSystemToBeDeleted] = useState();
    const [filteredSystemsCount, setFilteredSystemsCount] = useState();
    const [filterOpen, setFilterOpen] = useState(false);
    const [deleteSystemLinks, setDeleteSystemLinks] = useState(false);
    const { siteAdmin } = useUserRoles();
    const styles = useStyles();
    const deleteStyles = useDeleteStyles();
    const rootStyles = useRootStyles();
    const settingsStyles = useSettingsStyles();
    const DELETE_SYSTEM = gql `
    mutation DeleteSystem($input: DeleteSystemInput!) {
      deleteSystem(input: $input) {
        systemId
      }
    }
  `;
    const onChangeRemoveSystemLinks = (_, data) => {
        setDeleteSystemLinks(data.checked);
    };
    const textTruncate = (text) => (_jsx(Text, { truncate: true, wrap: false, className: styles.text, children: text }));
    const onClickOpenDetailsView = (system, gateways) => {
        openDetailsView(true);
        if (system.viaGateway) {
            const gateway = gateways.find((gateway) => gateway.id === system.viaGateway);
            setSystemToShowInDetailsView({ ...system, viaGateway: gateway });
        }
        else {
            setSystemToShowInDetailsView(system);
        }
    };
    const menuCard = (system, gateways) => {
        const menuItems = [
            {
                key: "system-menu-card-1",
                name: "Details",
                icon: _jsx(Eye20Regular, {}),
                onClick: () => {
                    onClickOpenDetailsView(system, gateways);
                },
            },
            {
                key: "system-menu-card-2",
                name: "Remove",
                icon: _jsx(Delete20Regular, {}),
                disabled: !!system.viaGateway,
                onClick: () => {
                    setSystemToBeDeleted(system);
                },
            },
        ];
        if (siteAdmin) {
            menuItems.pop();
        }
        return menuItems;
    };
    const includesLowerCase = (string, substring) => {
        return string.toLowerCase().includes(substring.toLowerCase());
    };
    const filterFunction = useCallback((system) => {
        let result = true;
        if (filter.id) {
            result = includesLowerCase(system.id, filter.id);
        }
        if (result && filter.name) {
            result = includesLowerCase(system.name, filter.name);
        }
        if (result && filter.type) {
            result = system.type === filter.type;
        }
        if (result && filter.gatewayId) {
            result = typeof system.viaGateway === "string" && includesLowerCase(system.viaGateway, filter.gatewayId);
        }
        if (result && filter.gatewayMachineName) {
            const filterGateways = gateways.filter((gateway) => includesLowerCase(gateway.machineName || "", filter.gatewayMachineName));
            result = filterGateways.some((gateway) => gateway.id === system.viaGateway);
        }
        return result;
    }, [filter, gateways]);
    const getGenericConnectionInformation = (system, key) => {
        const keyValueText = system.connectionInformation.find((element) => element.key === key);
        return (_jsx(_Fragment, { children: _jsxs("div", { className: styles.line, children: [_jsx(SystemIcon, { type: system.type }), textTruncate((keyValueText === null || keyValueText === void 0 ? void 0 : keyValueText.value) || "")] }, system.name) }));
    };
    const renderGatewaySystemConnectionInformation = (tenantIdOrKey, system, gateways, systemIcon, navigate) => {
        const gateway = gateways.find((gateway) => gateway.id === system.viaGateway);
        return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.line, children: [systemIcon, textTruncate(system.name)] }), gateway && (_jsxs("div", { className: mergeClasses(styles.line, styles.link), onClick: (e) => {
                        e === null || e === void 0 ? void 0 : e.preventDefault();
                        navigate(`/${tenantIdOrKey}/settings/gateways`);
                    }, children: [_jsx(ServerMultiple20Regular, {}), _jsx("div", { children: _jsx("span", { className: styles.linkText, children: gateway.machineName }) })] }))] }));
    };
    const getConnectionInformation = (tenantIdOrKey, system, gateways, navigate, isSiteAdmin) => {
        switch (system.type) {
            case SystemKind.Database:
                return getDatabaseConnectionInformation(tenantIdOrKey, system, gateways, navigate, isSiteAdmin);
            case SystemKind.DevOps:
                return getGenericConnectionInformation(system, "organizationName");
            case SystemKind.MSDataverse:
                return getGenericConnectionInformation(system, "organizationUrl");
            case SystemKind.SAP:
                return getSAPConnectionInformation(tenantIdOrKey, system, gateways, navigate);
            default:
                return [];
        }
    };
    const getSAPConnectionInformation = (tenantIdOrKey, system, gateways, navigate) => {
        const SAPIcon = _jsx(SystemIcon, { type: SystemKind.SAP });
        return renderGatewaySystemConnectionInformation(tenantIdOrKey, system, gateways, SAPIcon, navigate);
    };
    const getDatabaseConnectionInformation = (tenantIdOrKey, system, gateways, navigate, isSiteAdmin) => {
        const dbIcon = _jsx(Database20Regular, {});
        let content = [];
        if (system.viaGateway === null) {
            if (isSiteAdmin) {
                content.push(_jsxs("div", { className: mergeClasses(styles.line, styles.order2), children: [_jsx(SystemIcon, { type: system.type }), textTruncate(system.name)] }, system.id));
            }
            else {
                system.connectionInformation.forEach((element) => {
                    switch (element.key.toLowerCase()) {
                        case "data source":
                            content.push(_jsxs("div", { className: mergeClasses(styles.line, styles.order2), children: [_jsx(SystemIcon, { type: system.type }), textTruncate(element.value)] }, element.key));
                            break;
                        case "initial catalog":
                            content.push(_jsxs("div", { className: mergeClasses(styles.line, styles.order1), children: [dbIcon, textTruncate(element.value)] }, element.key));
                            break;
                    }
                });
            }
        }
        else {
            content.push(renderGatewaySystemConnectionInformation(tenantIdOrKey, system, gateways, dbIcon, navigate));
        }
        return content;
    };
    useEffect(() => {
        const systemsFiltered = systems.filter(filterFunction);
        setGroupedSystems(groupSystemsByType(systemsFiltered, selectedGroup));
        setFilteredSystems(systemsFiltered);
        setFilteredSystemsCount(systemsFiltered.length);
    }, [applied, filter, selectedGroup, systems, filterFunction]);
    return (_jsxs(_Fragment, { children: [systemToBeDeleted && (_jsx(DeleteDialog, { itemName: systemToBeDeleted.name, title: `Remove the System ${systemToBeDeleted.name}`, deletingMessage: "Removing the System", successMessage: `The System ${systemToBeDeleted.name} has been successfully removed.`, errorMessageTitle: "An error occurred while attempting to remove the System", itemId: systemToBeDeleted.id, mutation: DELETE_SYSTEM, deleteInput: {
                    tenantIdOrKey: tenantIdOrKey,
                    systemIdOrName: systemToBeDeleted.id,
                    deleteSystemLinks: deleteSystemLinks,
                }, queryToRefetch: "tenant", resetItemToBeDeleted: setSystemToBeDeleted, deleteReturnPropertyName: "deleteSystem", children: _jsxs(_Fragment, { children: [_jsx("div", { className: deleteStyles.message, children: "Removing this system may harm the integration stability." }), _jsx("div", { className: deleteStyles.message, children: "Do you really want to remove it?" }), _jsx("div", { className: deleteStyles.link, children: _jsx("div", { children: _jsx(Switch, { onChange: onChangeRemoveSystemLinks, label: "Also remove its System Links", "data-testid": "remove-system-links-check" }) }) })] }) })), _jsxs("div", { className: rootStyles.wrapper, children: [_jsx("div", { className: settingsStyles.header, children: _jsx(Filter, { filter: () => {
                                // TODO: Clarify with Sasha the UI for filtering
                            }, clear: () => {
                                dispatch(resetFilter());
                                setGroupedSystems(groupSystemsByType(systems, selectedGroup));
                                setFilteredSystems(systems);
                                setFilteredSystemsCount(0);
                            }, disabled: !active, applied: applied, filteredItemsCount: filteredSystemsCount, onToggle: (open) => {
                                setFilterOpen(open);
                            }, children: _jsx(SystemFilter, {}) }) }), _jsx(Title1, { className: rootStyles.pageTitle, "data-testid": "title", children: "Systems" }), _jsx("div", { className: rootStyles.tabList, children: _jsx(TabList, { defaultSelectedValue: selectedGroup, appearance: "transparent", "data-testid": "grouping-pane", children: getSystemGroups().map((group) => {
                                return (_jsx(Tab, { value: group, onClick: () => {
                                        setSelectedGroup(group);
                                        setGroupedSystems(groupSystemsByType(filteredSystems, group));
                                    }, children: `${getFormattedGroupName(group)} (${groupSystemsByType(systems, group).length})` }, group));
                            }) }) }), groupedSystems.length === 0 ? (_jsx(NoSystems, { showMessage: systems.length === 0 })) : (_jsx("div", { className: styles.container, children: groupedSystems
                            .slice()
                            .sort((systemA, systemB) => systemA.name.localeCompare(systemB.name))
                            .map((system) => {
                            return (_jsx("div", { className: styles.cardContainer, "data-testid": "system-card", children: _jsx(Card, { headerText: system.name, activeLinkHeader: !filterOpen, onClickHeader: () => {
                                        onClickOpenDetailsView(system, gateways);
                                    }, menuItems: menuCard(system, gateways), children: _jsx("div", { className: styles.cardBody, "data-testid": "system-card-body", children: _jsx("div", { className: styles.cardBodyItem, children: getConnectionInformation(tenantIdOrKey, system, gateways, navigate, siteAdmin) }) }) }, `Card-${system.id}`) }, system.id));
                        }) }))] })] }));
};
export default SystemsCards;
