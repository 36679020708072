import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect, forwardRef, useImperativeHandle, useId, useState } from "react";
import { Body1Stronger, Button, Divider, Link, makeStyles, mergeClasses, ProgressBar, shorthands, Toast, Toaster, ToastTitle, ToastTrigger, tokens, useId as useIdFluent, useToastController, } from "@fluentui/react-components";
import { useSelector, useDispatch } from "react-redux";
import { setFilesToUpload, initError, initSuccess } from "../../../redux/jobsSlice";
import { useDragDrop, UseDragDropEvents } from "../../../hooks";
import { LOAD_FILE_ERRORS } from "../../../api/api";
import { getErrorMessage } from "../../Error/errorMessages";
const useStyles = makeStyles({
    root: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        height: "inherit",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    input: {
        display: "none",
    },
    fileList: {
        height: "inherit",
        flexGrow: 1,
        overflowY: "auto",
        fontSize: "0.875rem",
        ...shorthands.padding(0, "1rem"),
    },
    fileListHeader: {
        display: "flex",
        position: "relative",
        lineHeight: "3rem",
        justifyContent: "space-between",
    },
    fileListHeaderName: {
        display: "flex",
    },
    fileItem: {
        flexDirection: "column",
        display: "flex",
        lineHeight: "3rem",
    },
    fileItemData: {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        lineHeight: "3rem",
    },
    drag: {
        backgroundColor: tokens.colorBrandBackground2Hover,
    },
    buttons: {
        display: "flex",
        position: "relative",
        lineHeight: "3rem",
        justifyContent: "space-between",
    },
});
const FileUploader = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        onClickPrimary: () => {
            onClickPrimaryEvent();
        },
    }));
    const { loaderFunction, text, textSecondary, dragTarget, successMessage, showButtons = true } = props;
    const [isDrag, setIsDrag] = useState(false);
    const fileInput = useRef(null);
    const dropZone = useRef(null);
    const styles = useStyles();
    const fileKeyBase = useId();
    let fileKey = 1;
    const { filesToUpload, error, sending, success } = useSelector((state) => state.jobs);
    const dispatch = useDispatch();
    const toasterId = useIdFluent("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const handleFileInput = (e) => {
        var _a;
        if ((_a = e.target) === null || _a === void 0 ? void 0 : _a.files.length) {
            const newFiles = [];
            Array.from(e.target.files).forEach((file) => newFiles.push(file));
            dispatch(setFilesToUpload(newFiles));
        }
    };
    // #region drag & drop
    const dragAndDropEvents = [
        {
            type: UseDragDropEvents.dragenter,
            handler: (e) => {
                setIsDrag(true);
            },
        },
        {
            type: UseDragDropEvents.dragleave,
            handler: (e) => {
                setIsDrag(true);
            },
        },
        {
            type: UseDragDropEvents.drop,
            handler: (e) => {
                var _a;
                setIsDrag(false);
                if (((_a = e.dataTransfer) === null || _a === void 0 ? void 0 : _a.files) && e.dataTransfer.files.length > 0) {
                    const newFiles = [];
                    Array.from(e.dataTransfer.files).forEach((file) => newFiles.push(file));
                    dispatch(setFilesToUpload(newFiles));
                    e.dataTransfer.clearData();
                }
            },
        },
    ];
    const [setTarget] = useDragDrop(dragAndDropEvents);
    // #endregion drag & drop
    useEffect(() => {
        const dragTargetElement = dragTarget && document.getElementById(dragTarget);
        const dragElement = dragTargetElement || (dropZone && dropZone.current);
        dragElement && setTarget(dragElement);
    }, [dragTarget, setTarget]);
    useEffect(() => {
        if (error && error.status === LOAD_FILE_ERRORS.FILE_UPDATED) {
            fileInput.current.value = null;
        }
        if (error)
            notify(getErrorMessage(error), "error");
    }, [error]);
    useEffect(() => {
        if (success && successMessage) {
            notify(successMessage, "success");
        }
    }, [success, successMessage]);
    const getFileInput = () => fileInput;
    const onClickPrimaryEvent = () => {
        getFileInput().current && getFileInput().current.click();
    };
    const pushFiles = () => {
        if (loaderFunction) {
            dispatch(loaderFunction(filesToUpload));
        }
    };
    const notify = (message, intent) => {
        const clearError = () => {
            if (intent === "success") {
                dispatch(initSuccess());
            }
            else {
                dispatch(initError());
            }
        };
        return dispatchToast(_jsx(Toast, { children: _jsx(ToastTitle, { action: _jsx(ToastTrigger, { children: _jsx(Link, { onClick: clearError, children: "Dismiss" }) }), children: _jsx("div", { "data-testid": "file-uploader-message", children: message }) }) }), { timeout: -1, intent });
    };
    return (_jsx("div", { ref: dropZone, className: mergeClasses(styles.root, isDrag && styles.drag), children: _jsxs("div", { className: styles.container, children: [_jsx("input", { title: "File Uploader", type: "file", ref: fileInput, onChange: handleFileInput, multiple: true, className: styles.input, "data-testid": "file-uploader", accept: ".json" }), showButtons && (_jsxs("div", { className: styles.buttons, children: [_jsx("div", { children: _jsx(Button, { appearance: "primary", onClick: onClickPrimaryEvent, children: text }) }), !!filesToUpload.length && _jsx(Button, { onClick: pushFiles, children: textSecondary })] })), _jsxs("div", { className: styles.fileList, children: [_jsxs("div", { className: styles.fileListHeader, children: [_jsx("div", { className: styles.fileListHeaderName, children: _jsx(Body1Stronger, { children: "File Name" }) }), _jsx(Body1Stronger, { children: "Size" })] }), _jsx("div", { children: filesToUpload.map((file) => (_jsxs("div", { className: styles.fileItem, children: [_jsxs("div", { className: styles.fileItemData, children: [_jsx("span", { children: file.name }), _jsx("span", { children: `${(file.size / 1024).toFixed()} KB` })] }), _jsx(Divider, {})] }, `${fileKeyBase}-${fileKey++}`))) })] }), sending && _jsx(ProgressBar, {}), _jsx(Toaster, { inline: true, toasterId: toasterId, position: "bottom-start" })] }) }));
});
export default FileUploader;
