import { createSlice } from "@reduxjs/toolkit";
export const initialState = "/";
const route = createSlice({
    name: "routePage",
    initialState: initialState,
    reducers: {
        setCurrentPage(state, action) {
            return action.payload;
        },
    },
});
export const { setCurrentPage } = route.actions;
export default route.reducer;
