import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles, mergeClasses, tokens } from "@fluentui/react-components";
import { Settings20Regular } from "@fluentui/react-icons";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useUserRoles } from "../../hooks";
const useStyles = makeStyles({
    icon: {
        color: tokens.colorNeutralBackground1,
        ":hover": { cursor: "pointer" },
        display: "flex",
    },
    iconRight: {
        paddingRight: "1.2em",
    },
});
const SettingsIcon = () => {
    const styles = useStyles();
    const { isTenantSettingsPageActive } = useSelector((state) => state.appState);
    const tenant = useSelector((state) => state.tenant);
    const { tenantAdmin, siteAdmin } = useUserRoles();
    return (!isTenantSettingsPageActive &&
        tenant.key &&
        (tenantAdmin || siteAdmin) && (_jsx(Link, { to: `/${tenant === null || tenant === void 0 ? void 0 : tenant.key}/settings/general`, children: _jsx(Settings20Regular, { "data-testid": "settings-icon", className: mergeClasses(styles.icon, styles.iconRight) }) })));
};
export default SettingsIcon;
