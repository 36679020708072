import { jsx as _jsx } from "react/jsx-runtime";
import { MenuList, makeStyles, mergeClasses, tokens, } from "@fluentui/react-components";
import NavItems from "./NavItems";
const useStyles = makeStyles({
    nav: {
        width: "100%",
        height: "100%",
        backgroundColor: tokens.colorNeutralBackground2,
    },
    menuList: {
        width: "calc(100% - 12px)",
        paddingLeft: "12px",
        backgroundColor: tokens.colorNeutralBackground2,
    },
    active: {
        backgroundColor: tokens.colorNeutralBackground3Hover,
    },
    noIcons: {
        width: "32px",
        height: "32px",
    },
});
const Nav = ({ groups, onLinkClick, selectedKey, onlyIcons }) => {
    const styles = useStyles();
    return (_jsx("nav", { role: "menu", className: styles.nav, children: _jsx(MenuList, { className: mergeClasses(styles.menuList, onlyIcons && styles.noIcons), children: _jsx(NavItems, { groups: groups, onLinkClick: onLinkClick, selectedKey: selectedKey, onlyIcons: onlyIcons }) }) }));
};
export default Nav;
