import { jsx as _jsx } from "react/jsx-runtime";
import { TableCellLayout, makeStyles, mergeClasses } from "@fluentui/react-components";
const useStyles = makeStyles({
    root: {
        ":hover": {
            cursor: "pointer",
        },
    },
    fixOverflow: {
        "& > div > span": {
            overflowY: "hidden",
        },
    },
    linkText: {
    // Remove next comment when Job has link active
    // color: tokens.colorBrandForegroundLink,
    },
});
const LinkText = ({ name, item, onClick }) => {
    const styles = useStyles();
    return (_jsx(TableCellLayout, { "data-testid": "link-cell-item", className: mergeClasses(onClick && styles.root, styles.fixOverflow), truncate: true, onClick: () => {
            onClick === null || onClick === void 0 ? void 0 : onClick(item);
        }, children: _jsx("span", { className: styles.linkText, children: name }) }));
};
export default LinkText;
