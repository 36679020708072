import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles, Persona } from "@fluentui/react-components";
import { createColumn } from "../listUtils";
const useStyles = makeStyles({
    lineHeight: {
        "& span:nth-child(2)": {
            lineHeight: "var(--lineHeightBase600)",
        },
    },
});
const createPersonaColumn = (columnId, headerText, showName) => {
    const createPersona = (persona, columnId) => {
        const styles = useStyles();
        return (_jsx(Persona, { "data-testid": "persona-cell-item", className: styles.lineHeight, name: persona[columnId], primaryText: showName ? undefined : "" }));
    };
    return createColumn(columnId, headerText, createPersona, true);
};
export { createPersonaColumn };
