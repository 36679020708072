import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ConnectionInformationDetails from "./ConnectionInformationDetails";
import Main from "./Main";
import GatewayDetail from "../../Gateways/Details/GatewayDetail";
import { details as useStyles } from "../../../shared/sharedStyles";
import { useUserRoles } from "../../../../hooks";
const SystemDetail = ({ system }) => {
    const styles = useStyles();
    const gateway = system.viaGateway ? system.viaGateway : null;
    const { tenantAdmin } = useUserRoles();
    return (_jsxs("div", { className: styles.root, children: [_jsx(Main, { system: system }), !gateway && tenantAdmin && _jsx(ConnectionInformationDetails, { connectionInformation: system.connectionInformation }), gateway && _jsx(GatewayDetail, { gateway: gateway, viaGateway: true })] }));
};
export default SystemDetail;
