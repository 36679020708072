var JobIconEnum;
(function (JobIconEnum) {
    JobIconEnum["running"] = "running";
    JobIconEnum["succeeded"] = "succeeded";
    JobIconEnum["queued"] = "queued";
    JobIconEnum["error"] = "error";
    JobIconEnum["successive"] = "successive";
    JobIconEnum["userLog"] = "userLog";
})(JobIconEnum || (JobIconEnum = {}));
export { JobIconEnum };
