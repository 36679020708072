import { gql } from "@apollo/client";
import { acquireApiToken } from "../Authentication/authAPI";
import { apiConfig } from "../Authentication/authConfig";
import { ClientError, ServerError, fetchWithRetry } from "./fetchWithRetry";
var LOAD_FILE_ERRORS;
(function (LOAD_FILE_ERRORS) {
    LOAD_FILE_ERRORS[LOAD_FILE_ERRORS["FILE_UPDATED"] = 460] = "FILE_UPDATED";
})(LOAD_FILE_ERRORS || (LOAD_FILE_ERRORS = {}));
const fetchRetryMinDelay = 1000;
const fetchRetryMaxDelay = 30000;
const fetchRetryAttemptCount = 7;
const EXECUTE_JOB = gql `
  mutation ExecuteJob(
    $tenantIdOrKey: String!
    $siteIdOrName: String!
    $jobDefinitionIdOrName: String!
    $parameters: [JobParameterGraphTypeInput!]
  ) {
    executeJob(
      input: {
        tenantIdOrKey: $tenantIdOrKey
        siteIdOrName: $siteIdOrName
        jobDefinitionIdOrName: $jobDefinitionIdOrName
        parameters: $parameters
      }
    ) {
      requestTime
      jobExecutionId
    }
  }
`;
const GET_TENANT = gql `
  query getTenant($tenantIdOrKey: String!) {
    tenant(tenantIdOrKey: $tenantIdOrKey) {
      azureTenants
      id
      key
      name
      sites {
        id
        name
      }
    }
  }
`;
const GET_ME = `
  query me($tenantIdOrKey: String!) {
    me {
      displayName
      principalId
      roleAssignments(tenantIdOrKey: $tenantIdOrKey) {
        principalId
        roleId
        siteId
        tenantId
      }
    }
  }
`;
/**
 * Get token for the API
 * @returns MSAL Token
 */
const getAuthAPIToken = async function () {
    const tokenResponse = await acquireApiToken();
    return tokenResponse.accessToken;
};
/**
 * Make a request to the API to upload job definitions
 * @param authTokenFn Authorization token function
 * @param tenantIdOrKey Tenant ID or Key
 * @param siteIdOrName Site ID or Name
 * @param body FormData with the files to upload
 * @returns Response
 */
const fetchUploadJobsDefinitions = async function (tenantIdOrKey, siteIdOrName, body) {
    const authToken = await getAuthAPIToken();
    const headers = new Headers({
        Authorization: `Bearer ${authToken}`,
    });
    const config = {
        method: "POST",
        headers: headers,
        body,
    };
    return await fetchWithRetry(`${apiConfig.apiEndPoint}/${apiConfig.rest}/jobdefinitions/${tenantIdOrKey}/${siteIdOrName}/upload`, config);
};
/**
 * Make a request to the API to get user info
 * @param authTokenFn Authorization token function
 * @param tenantIdOrKey Tenant ID or Key
 * @returns Response
 */
const getMeInfo = async function (tenantIdOrKey) {
    const authToken = await getAuthAPIToken();
    const response = await fetchWithRetry(`${apiConfig.apiEndPoint}/${apiConfig.graphQL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/graphql-response+json",
            Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
            query: GET_ME,
            variables: { tenantIdOrKey },
        }),
    });
    return await response.json();
};
/**
 * Upload files to the system
 * @param files array of Files
 * @param tenantIdOrKey Tenant id or key
 * @param siteIdOrName Site id or name
 * @returns
 */
const uploadJobs = async (files, tenantIdOrKey, siteIdOrName) => {
    if (!tenantIdOrKey)
        return Promise.reject({ status: 400, message: "Tenant's ID or key must be specified" });
    if (!siteIdOrName)
        return Promise.reject({ status: 400, message: "Site's ID or name must be specified" });
    if (files.length === 0)
        return Promise.reject({ status: 400, message: "The files to upload should have at least one item" });
    const formData = new FormData();
    for (let file of files)
        formData.append("files", file);
    try {
        return await fetchUploadJobsDefinitions(tenantIdOrKey, siteIdOrName, formData);
    }
    catch (error) {
        if (error instanceof ClientError && error.status === LOAD_FILE_ERRORS.FILE_UPDATED) {
            return Promise.reject({
                status: error.status,
                message: files.length > 1
                    ? "Some job files were updated. Please, select job files again"
                    : "The job file was updated. Please, select the job file again",
            });
        }
        else if (error instanceof ClientError || error instanceof ServerError) {
            return Promise.reject({ status: error.status, message: error.message });
        }
        else {
            return Promise.reject(error);
        }
    }
};
export { EXECUTE_JOB, GET_TENANT, LOAD_FILE_ERRORS, getMeInfo, fetchRetryAttemptCount, fetchRetryMaxDelay, fetchRetryMinDelay, uploadJobs, };
