import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Field, Input as InputFluent, makeStyles, mergeClasses, Textarea, tokens, useId, } from "@fluentui/react-components";
import { Info12Regular, Warning12Regular } from "@fluentui/react-icons";
const useStyles = makeStyles({
    validationTextArea: {
        display: "flex",
        alignItems: "center",
        columnGap: "0.1rem",
        fontSize: "0.6rem",
    },
    validationTextAreaWarning: {
        color: tokens.colorPaletteDarkOrangeBorderActive,
    },
    labelText: {
        fontWeight: "600",
        fontSize: "0.875rem",
    },
});
const Input = ({ label, maxLength, onChange, onValidation, placeholder, required, resize, type, classNames, value = "", }) => {
    const styles = useStyles();
    const inputId = useId();
    const [inputValue, setInputValue] = useState(value);
    const [validation, setValidation] = useState({
        message: `Max length is ${maxLength} characters`,
        icon: _jsx(Info12Regular, {}),
        state: "none",
    });
    useEffect(() => {
        setInputValue(value);
    }, [value]);
    const OnChangeParameter = (syntheticEvent, data) => {
        let isValid = true;
        let dataAfterValidation = data.value;
        if (syntheticEvent.nativeEvent instanceof InputEvent &&
            syntheticEvent.nativeEvent.inputType === "insertFromPaste" &&
            data.value.length > maxLength) {
            dataAfterValidation = data.value.slice(0, maxLength);
            setInputValue(dataAfterValidation);
            setValidation({
                message: `Pasted text has been truncated to fit in ${maxLength} characters.`,
                icon: _jsx(Warning12Regular, {}),
                state: "warning",
            });
        }
        else if (data.value.length < maxLength) {
            setValidation({
                message: `Max length is ${maxLength} characters. ${data.value.length}/${maxLength}`,
                icon: _jsx(Info12Regular, {}),
                state: "none",
            });
            if (onValidation) {
                isValid = onValidation(data.value, setValidation);
            }
            setInputValue(data.value);
        }
        else if (data.value.length >= maxLength) {
            setValidation({
                message: `Max length is ${maxLength} characters. ${maxLength}/${maxLength}`,
                icon: _jsx(Warning12Regular, {}),
                state: "warning",
            });
            dataAfterValidation = data.value.slice(0, maxLength);
        }
        onChange(dataAfterValidation, isValid);
    };
    const validationMessage = (_jsxs("span", { className: mergeClasses(styles.validationTextArea, validation.state === "warning" && styles.validationTextAreaWarning), children: [validation.icon, validation.message] }));
    const textInput = type === "text" ? (_jsx(InputFluent, { id: inputId, className: classNames, type: "text", placeholder: placeholder, size: "medium", value: inputValue, onChange: OnChangeParameter })) : (_jsx(Textarea, { id: inputId, className: classNames, resize: resize, placeholder: placeholder, size: "medium", value: inputValue, onChange: OnChangeParameter }));
    const labelText = `${label}${required ? "*" : ""}`;
    return (_jsx(Field, { label: { children: labelText, className: styles.labelText }, validationState: "none", validationMessage: validationMessage, children: textInput }));
};
export default Input;
