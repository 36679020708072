import { createSlice } from "@reduxjs/toolkit";
export var UserRoles;
(function (UserRoles) {
    UserRoles[UserRoles["ServiceProvider"] = 0] = "ServiceProvider";
    UserRoles[UserRoles["TenantAdmin"] = 1] = "TenantAdmin";
    UserRoles[UserRoles["SiteAdmin"] = 2] = "SiteAdmin";
    UserRoles[UserRoles["JobDesigner"] = 3] = "JobDesigner";
    UserRoles[UserRoles["Consumer"] = 4] = "Consumer";
})(UserRoles || (UserRoles = {}));
export const initialState = {
    principalId: "",
    displayName: "",
    roleAssignments: [],
};
const userInfoSlice = createSlice({
    name: "userInfo",
    initialState,
    reducers: {
        setUserInfo(state, action) {
            state.principalId = action.payload.principalId;
            state.displayName = action.payload.displayName;
            state.roleAssignments = action.payload.roleAssignments;
        },
        resetUserInfo(state) {
            state.principalId = initialState.principalId;
            state.displayName = initialState.displayName;
            state.roleAssignments = initialState.roleAssignments;
        },
    },
});
export const { setUserInfo, resetUserInfo } = userInfoSlice.actions;
export default userInfoSlice.reducer;
