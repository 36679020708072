import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isValidElement, useEffect, useRef, useState, } from "react";
import { Divider, makeStyles, shorthands, tokens, } from "@fluentui/react-components";
import { Dismiss20Regular } from "@fluentui/react-icons";
const Body = ({ children }) => _jsx(_Fragment, { children: children });
const Footer = ({ children }) => _jsx(_Fragment, { children: children });
const detailsWidth = "32rem";
const padding = "1rem";
const closeSize = "2rem";
const footerHeight = "4rem";
const useStyles = makeStyles({
    details: {
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        minWidth: detailsWidth,
        backgroundColor: tokens.colorNeutralBackground1,
        boxShadow: tokens.shadow16,
        zIndex: 999999,
        paddingBottom: "0.5em",
        display: "flex",
        flexDirection: "column",
    },
    header: {
        ...shorthands.padding("1.2rem", padding),
        display: "flex",
        flexBasis: "fit-content",
    },
    closeButton: {
        position: "absolute",
        top: "1.2rem",
        right: "1.25rem",
        color: tokens.colorNeutralForeground2,
        fontSize: "1rem",
        width: closeSize,
        height: closeSize,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        ":hover": {
            backgroundColor: tokens.colorBrandBackground,
            color: tokens.colorNeutralBackground1,
        },
    },
    title: {
        height: "fit-content",
        width: `calc(${detailsWidth} - ${closeSize} * 2)`,
        lineHeight: closeSize,
        fontSize: "1.25rem",
        fontFamily: "Segoe UI Semibold",
        minWidth: 0,
        wordBreak: "normal",
        overflowWrap: "anywhere",
    },
    body: {
        flexGrow: 1,
        position: "relative",
        ...shorthands.overflow("auto"),
    },
    bodyWrapper: {
        ...shorthands.padding(padding),
        ...shorthands.overflow("hidden"),
    },
    footer: {
        flexBasis: footerHeight,
    },
    footerWrapper: {
        ...shorthands.padding(padding),
    },
    modal: {
        position: "fixed",
        backgroundColor: tokens.colorNeutralForeground2,
        opacity: 0.2,
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        zIndex: 9999,
    },
});
const Details = ({ title, children, isOpen, onDismiss, modal, type = "wrappedBody", }) => {
    let detailsChildren = isValidElement(children)
        ? [children]
        : children;
    const body = detailsChildren.find((child) => child.type === Body);
    const footer = detailsChildren.find((child) => child.type === Footer);
    const headerRef = useRef(null);
    const bodyRef = useRef(null);
    const styles = useStyles();
    const [showDivider, setShowDivider] = useState(false);
    useEffect(() => {
        const bodyElement = bodyRef.current;
        const handleResize = () => {
            if (bodyElement) {
                setShowDivider(bodyElement.scrollHeight > bodyElement.clientHeight);
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [body, footer]);
    if (!isOpen) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [modal && _jsx("div", { "data-testid": "details-fade", className: styles.modal }), _jsxs("div", { "data-testid": "details", className: styles.details, children: [_jsxs("div", { className: styles.header, children: [_jsx("div", { "data-testid": "details-header-close-button", className: styles.closeButton, onClick: () => onDismiss(), children: _jsx(Dismiss20Regular, {}) }), _jsx("div", { "data-testid": "details-header-title", ref: headerRef, className: styles.title, children: title })] }), _jsxs("div", { "data-testid": "details-body", ref: bodyRef, className: styles.body, children: [type === "fullBody" && body, type === "wrappedBody" && (_jsx("div", { className: styles.bodyWrapper, children: body }))] }), footer && (_jsxs(_Fragment, { children: [showDivider && _jsx(Divider, {}), _jsx("div", { "data-testid": "details-footer", className: styles.footer, children: _jsx("div", { className: styles.footerWrapper, children: footer }) })] }))] })] }));
};
Details.Body = Body;
Details.Footer = Footer;
export default Details;
export { detailsWidth };
