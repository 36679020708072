import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from "@apollo/client/react/hooks/useMutation";
import { ProgressBar } from "@fluentui/react-components";
import { useState } from "react";
import { deleteModal as useStyles } from "../../components/shared/sharedStyles";
import { Modal, ModalButtonTypeEnum, ModalTypeEnum } from "../../library/src";
const DeleteErrorDialog = ({ title, error, errorMessageTitle, onDismiss }) => {
    const styles = useStyles();
    return (_jsx(Modal, { isOpen: true, title: title, type: ModalTypeEnum.Error, onDismiss: onDismiss, buttons: [
            {
                id: "error-ok",
                type: ModalButtonTypeEnum.Primary,
                text: "OK",
                onClick: onDismiss,
            },
        ], children: _jsxs("div", { className: styles.error, children: [_jsx("div", { className: styles.title, children: errorMessageTitle }), _jsx("div", { className: styles.message, children: error })] }) }, "delete-error"));
};
const DeleteSuccessDialog = ({ title, successMessage, onDismiss }) => {
    return (_jsx(Modal, { isOpen: true, title: title, type: ModalTypeEnum.Info, onDismiss: onDismiss, buttons: [
            {
                id: "success-ok",
                type: ModalButtonTypeEnum.Primary,
                text: "OK",
                onClick: onDismiss,
            },
        ], children: _jsx("div", { children: successMessage }) }, "delete-success"));
};
const DeleteDialog = ({ itemName, itemId, title, message, deletingMessage, successMessage, errorMessageTitle, mutation, deleteInput, queryToRefetch, deleteReturnPropertyName, resetItemToBeDeleted, onSuccess, children, }) => {
    const [buttonDisabled, setButtonDisabled] = useState({
        yes: false,
        no: false,
    });
    const styles = useStyles();
    const [deleteMutation, { data, loading, error, client }] = useMutation(mutation);
    if (!itemName || !itemId) {
        return _jsx(_Fragment, {});
    }
    if (error || (data && !data[deleteReturnPropertyName])) {
        return (_jsx(DeleteErrorDialog, { name: itemName, title: title, errorMessageTitle: errorMessageTitle, error: error === null || error === void 0 ? void 0 : error.message, onDismiss: () => resetItemToBeDeleted() }));
    }
    if (data && data[deleteReturnPropertyName]) {
        return (_jsx(DeleteSuccessDialog, { name: itemName, title: title, onDismiss: () => {
                client.refetchQueries({ include: [queryToRefetch] });
                onSuccess && onSuccess();
                resetItemToBeDeleted();
            }, successMessage: successMessage }));
    }
    return (_jsx(Modal, { isOpen: true, type: ModalTypeEnum.Warning, title: title, onDismiss: () => resetItemToBeDeleted(), buttons: [
            {
                id: "delete-yes",
                type: ModalButtonTypeEnum.Primary,
                text: "Yes",
                disabled: buttonDisabled.yes,
                onClick: () => {
                    setButtonDisabled({ yes: true, no: true });
                    deleteMutation({
                        variables: {
                            input: deleteInput,
                        },
                    });
                },
            },
            {
                id: "delete-no",
                type: ModalButtonTypeEnum.Secondary,
                text: "No",
                disabled: buttonDisabled.no,
                onClick: () => resetItemToBeDeleted(),
            },
        ], children: _jsxs("div", { className: styles.root, children: [message ? _jsx("div", { className: styles.message, children: message }) : _jsx(_Fragment, { children: children }), loading && (_jsxs("div", { children: [deletingMessage, _jsx(ProgressBar, { max: 42 })] }))] }) }, "delete-system"));
};
export default DeleteDialog;
