import { jsx as _jsx } from "react/jsx-runtime";
import { Button, DialogActions, DialogTrigger, } from "@fluentui/react-components";
const ModalFooter = ({ buttons }) => {
    const hasDuplicateIds = () => {
        let ids = buttons.map((button) => button.id);
        return new Set(ids).size !== buttons.length;
    };
    if (hasDuplicateIds()) {
        throw new Error(`Duplicate id in modal footer buttons are not allowed`);
    }
    return (_jsx(DialogActions, { "data-testid": "modal-footer", children: buttons.map((button) => (_jsx(DialogTrigger, { "data-testid": "modal-footer", children: _jsx(Button, { appearance: button.type, "data-testid": `modal-footer-button-${button.id}`, disabled: button.disabled, onClick: () => {
                    button.onClick();
                }, children: button.text }) }, button.id))) }));
};
export default ModalFooter;
