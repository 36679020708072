import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import useElementResize from "../../../../../hooks/useElementResize";
import { makeStyles, shorthands, Text, tokens, Tooltip, } from "@fluentui/react-components";
import { Info20Regular } from "@fluentui/react-icons";
const useStyles = makeStyles({
    text: {
        ...shorthands.overflow("hidden"),
        display: "block",
    },
    infoIcon: {
        color: tokens.colorBrandForegroundLink,
        width: "3em",
    },
});
const Parameter = ({ parameters }) => {
    const styles = useStyles();
    const [showInfoIcon, setShowInfoIcon] = useState(false);
    const { size, ref: textReference } = useElementResize();
    useEffect(() => {
        if (textReference.current) {
            const element = textReference.current;
            setShowInfoIcon(element.scrollWidth > element.clientWidth);
        }
    }, [size]);
    const text = parameters
        .map((param) => {
        const name = param.name;
        const value = "value" in param ? param.value : "";
        return `${name}${value ? `: ${value}` : ""}`;
    })
        .join(", ");
    return (_jsxs(_Fragment, { children: [_jsx(Text, { ref: textReference, "data-testid": "parameter-cell-item", truncate: true, wrap: false, className: styles.text, children: text }), showInfoIcon && (_jsx(Tooltip, { content: text !== null && text !== void 0 ? text : "", relationship: "description", withArrow: true, children: _jsx(Info20Regular, { className: styles.infoIcon }) }))] }));
};
export default Parameter;
