import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Input } from "../../../Inputs";
import { makeStyles } from "@fluentui/react-components";
const useStyles = makeStyles({
    height: {
        height: "11rem",
    },
});
const InputDescription = ({ onChange, value }) => {
    const styles = useStyles();
    const [valueInputName, setValueInputName] = useState(value);
    useEffect(() => {
        setValueInputName(value);
    }, [value]);
    return (_jsx(Input, { label: "Description", type: "textArea", value: valueInputName, maxLength: 256, classNames: styles.height, onChange: onChange }));
};
export default InputDescription;
