import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import "./errorBoundary.scss";
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: undefined };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }
    componentDidCatch(error, errorInfo) {
        console.error("ErrorBoundary caught an error", error, errorInfo);
    }
    resetErrorBoundary() {
        this.setState({ hasError: false, error: undefined });
    }
    render() {
        var _a;
        if (this.state.hasError) {
            return (_jsxs("div", { className: "error-boundary", children: [_jsx("div", { className: "error-boundary__header", children: _jsx("div", { className: "error-boundary__header__logo", children: "PSLink" }) }), _jsxs("div", { className: "error-boundary__body", children: [_jsx("h1", { className: "error-boundary__body__title", children: "Something went wrong." }), _jsxs("div", { className: "error-boundary__body__error", children: [_jsx("div", { className: "error-boundary__body__error__title", children: "Error:" }), _jsx("div", { className: "error-boundary__body__error__message", children: (_a = this.state.error) === null || _a === void 0 ? void 0 : _a.message })] })] })] }));
        }
        return _jsx(_Fragment, { children: this.props.children });
    }
}
export default ErrorBoundary;
