import { useState, useEffect } from "react";
const useResize = (resizeFunction) => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        const resizeListener = () => {
            // Run resize function
            resizeFunction && resizeFunction();
        };
        window.addEventListener("resize", resizeListener);
        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener("resize", resizeListener);
        };
    }, [resizeFunction]);
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
};
export default useResize;
