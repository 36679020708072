import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { Route, Routes, useParams } from "react-router-dom";
import { Spinner, makeStyles } from "@fluentui/react-components";
import { shorthands } from "@fluentui/react-components";
import { GET_TENANT } from "./api/api";
import { resetTenant, setTenant } from "./redux/tenantsSlice";
import { setSelectedSite } from "./redux/appStateSlice";
import Home from "./components/Home";
import { Settings } from "./components/Settings";
import { AppStateError, ErrorDisplay, mapApolloErrorToErrorMessage } from "./components/Error";
const useStyles = makeStyles({
    spinner: {
        ...shorthands.margin("22%", "47%"),
    },
});
const Pages = () => {
    const dispatch = useDispatch();
    const styles = useStyles();
    const { tenantIdOrKey } = useParams();
    const appStateError = useSelector((state) => state.appState.error);
    const userInfo = useSelector((state) => state.userInfo);
    const { loading, error, data } = useQuery(GET_TENANT, {
        variables: { tenantIdOrKey },
        onCompleted: (data) => {
            dispatch(setTenant(data.tenant));
            dispatch(setSelectedSite(data.tenant.sites[0]));
        },
        onError: () => {
            dispatch(resetTenant());
        },
    });
    if (appStateError) {
        return _jsx(AppStateError, {});
    }
    else if (error) {
        return _jsx(ErrorDisplay, { error: mapApolloErrorToErrorMessage(error) });
    }
    else if (loading || userInfo.displayName === "") {
        return _jsx(Spinner, { label: "Loading...", size: "huge", className: styles.spinner });
    }
    else if (data) {
        return (_jsxs(Routes, { children: [_jsx(Route, { path: "settings/*", element: _jsx(Settings, {}) }), _jsx(Route, { path: "/*", element: _jsx(Home, {}) })] }));
    }
};
export default Pages;
