import { jsx as _jsx } from "react/jsx-runtime";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { Button } from "@fluentui/react-components";
const SignInSignOutButton = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const logOut = () => {
        instance.logoutRedirect();
    };
    const logIn = () => {
        instance.loginRedirect();
    };
    if (isAuthenticated) {
        return _jsx(Button, { onClick: logOut, children: "Logout" });
    }
    else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
        // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
        return _jsx(Button, { onClick: logIn, children: "Login" });
    }
    else {
        return null;
    }
};
export default SignInSignOutButton;
