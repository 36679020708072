import { createSlice } from "@reduxjs/toolkit";
export const initialState = {
    burgerIconIsActive: false,
    comeBackLocationPath: "",
    error: false,
    settingsPageToNavigate: "",
    isTenantSettingsPageActive: false,
    selectedSite: {
        id: "",
        name: "",
    },
};
const appState = createSlice({
    name: "appState",
    initialState,
    reducers: {
        toggleBurgerIcon(state) {
            state.burgerIconIsActive = !state.burgerIconIsActive;
        },
        setBurgerIcon(state, action) {
            state.burgerIconIsActive = action.payload;
        },
        setComeBackLocationPath(state, action) {
            state.comeBackLocationPath = action.payload;
        },
        setError(state) {
            state.error = true;
        },
        navigateToSettingsPages(state, action) {
            state.settingsPageToNavigate = action.payload;
        },
        setIsTenantSettingsPageActive(state, action) {
            state.isTenantSettingsPageActive = action.payload;
        },
        setSelectedSite(state, action) {
            state.selectedSite = action.payload;
        },
    },
});
export const { toggleBurgerIcon, setBurgerIcon, setComeBackLocationPath, setError, navigateToSettingsPages, setIsTenantSettingsPageActive, setSelectedSite, } = appState.actions;
export default appState.reducer;
