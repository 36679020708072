import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Modal, ModalButtonTypeEnum } from "../../../library/src";
import { Field, makeStyles, mergeClasses, shorthands, Textarea, tokens, useId, } from "@fluentui/react-components";
import { Info12Regular, Warning12Filled } from "@fluentui/react-icons";
import { useSelector } from "react-redux";
const MAX_PARAMETER_LENGTH = 256;
const useStyles = makeStyles({
    parameterInput: {
        height: "fit-content",
    },
    label: {
        width: "34rem",
        display: "flex",
        flexDirection: "column",
    },
    labelParameterName: {
        overflowWrap: "break-word",
        ...shorthands.overflow("hidden"),
        fontWeight: "600",
    },
    labelParameterDescription: {
        overflowWrap: "break-word",
        ...shorthands.overflow("hidden"),
        fontSize: "0.6rem",
    },
    validationTextArea: {
        display: "flex",
        alignItems: "center",
        columnGap: "0.1rem",
        fontSize: "0.6rem",
    },
    validationTextAreaWarning: {
        color: tokens.colorPaletteDarkOrangeBorderActive,
    },
});
const ParameterModal = ({ parameterValue, setParameterValue, runFromParameterModal, cancelParameterModal, }) => {
    const styles = useStyles();
    const selectedJob = useSelector((state) => state.jobs.selectedJob);
    const [validationTextArea, setValidationTextArea] = React.useState({
        message: `Max length is ${MAX_PARAMETER_LENGTH} characters`,
        icon: _jsx(Info12Regular, {}),
        state: "none",
    });
    const parameterInputId = useId("parameterInput");
    const OnChangeParameter = (syntheticEvent, data) => {
        if (syntheticEvent.nativeEvent instanceof InputEvent &&
            syntheticEvent.nativeEvent.inputType === "insertFromPaste" &&
            data.value.length > MAX_PARAMETER_LENGTH) {
            setParameterValue(data.value.slice(0, MAX_PARAMETER_LENGTH));
            setValidationTextArea({
                message: `Pasted text has been truncated to fit in ${MAX_PARAMETER_LENGTH} characters.`,
                icon: _jsx(Warning12Filled, {}),
                state: "warning",
            });
        }
        else if (data.value.length <= MAX_PARAMETER_LENGTH) {
            setValidationTextArea({
                message: `Max length is ${MAX_PARAMETER_LENGTH} characters. ${data.value.length}/${MAX_PARAMETER_LENGTH}`,
                icon: _jsx(Info12Regular, {}),
                state: "none",
            });
            setParameterValue(data.value);
        }
    };
    if (selectedJob.parameters.length === 0) {
        return null;
    }
    const label = (_jsxs("div", { className: styles.label, children: [_jsx("div", { className: styles.labelParameterName, children: selectedJob.parameters[0].name }), _jsx("div", { className: styles.labelParameterDescription, children: selectedJob.parameters[0].description })] }));
    return (_jsx(Modal, { isOpen: true, onDismiss: cancelParameterModal, title: `Please define the parameter for "${selectedJob.jobDefinitionIdOrName}"`, buttons: [
            {
                id: "run",
                type: ModalButtonTypeEnum.Primary,
                text: "Run",
                onClick: () => runFromParameterModal([{ name: selectedJob.parameters[0].name, value: parameterValue }]),
            },
            {
                id: "cancel",
                type: ModalButtonTypeEnum.Secondary,
                text: "Cancel",
                onClick: cancelParameterModal,
            },
        ], children: _jsx("div", { className: styles.parameterInput, children: _jsx(Field, { label: label, validationState: "none", validationMessage: _jsxs("span", { className: mergeClasses(styles.validationTextArea, validationTextArea.state === "warning" && styles.validationTextAreaWarning), children: [validationTextArea.icon, validationTextArea.message] }), children: _jsx(Textarea, { id: parameterInputId, size: "large", resize: "vertical", value: parameterValue, onChange: OnChangeParameter, placeholder: `Enter value` }) }) }) }));
};
export default ParameterModal;
