import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles, shorthands, mergeClasses, Persona, Badge, Text, Divider, tokens, } from "@fluentui/react-components";
import SignInSignOutButton from "./SignInSignOutButton";
import { DefaultPadding } from "../shared/sharedStyles";
const useStyles = makeStyles({
    root: {
        maxWidth: "20rem",
        ...shorthands.padding(DefaultPadding),
    },
    flex: {
        display: "flex",
    },
    columnDirection: {
        flexDirection: "column",
    },
    header: {
        color: tokens.colorStrokeFocus2,
        paddingBottom: "0.625rem",
        alignItems: "center",
    },
    body: {
        paddingTop: "1.25rem",
    },
    text: {
        ...shorthands.overflow("hidden"),
        maxWidth: "15rem",
        textOverflow: "ellipsis",
        display: "block",
    },
    tenant: {
        paddingTop: "0.625rem",
    },
    badge: {
        backgroundColor: tokens.colorBrandForegroundLink,
    },
    tenantName: {
        paddingLeft: "0.625rem",
        color: tokens.colorNeutralForeground2,
    },
});
const UserCallout = ({ tenantName, name, email }) => {
    const styles = useStyles();
    return (_jsxs("div", { className: mergeClasses(styles.root, styles.flex, styles.columnDirection), children: [_jsxs("div", { className: mergeClasses(styles.flex, styles.header), children: [_jsx(Persona, { "data-testid": "user-callout-persona", name: name, primaryText: "", avatar: { color: "colorful" }, size: "huge" }), _jsxs("div", { className: mergeClasses(styles.flex, styles.columnDirection), children: [_jsx(Text, { className: styles.text, size: 500, weight: "bold", truncate: true, wrap: false, children: name }), _jsx(Text, { className: styles.text, weight: "bold", children: email }), _jsxs("div", { className: mergeClasses(styles.flex, styles.tenant), children: [_jsx(Badge, { className: styles.badge }), _jsx(Text, { className: styles.tenantName, children: tenantName })] })] })] }), _jsx(Divider, {}), _jsx("div", { className: styles.body, children: _jsx(SignInSignOutButton, {}) })] }));
};
export default UserCallout;
