import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { makeStyles, Subtitle2Stronger, Text } from "@fluentui/react-components";
import { details as useDetails } from "../../../shared/sharedStyles";
const useStyles = makeStyles({
    overflowWrap: {
        overflowWrap: "anywhere",
    },
});
const Description = ({ description }) => {
    const styles = useStyles();
    const stylesDetails = useDetails();
    return description ? (_jsxs("div", { className: stylesDetails.section, "data-testid": "site-details-description", children: [_jsx(Subtitle2Stronger, { className: stylesDetails.sectionTitle, children: "Description" }), _jsx("div", { className: stylesDetails.row, children: _jsx("div", { children: _jsx(Text, { className: styles.overflowWrap, children: description }) }) })] })) : (_jsx(_Fragment, {}));
};
export default Description;
