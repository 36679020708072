import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Route, Routes } from "react-router-dom";
import { AppStateError } from "../Error";
import { NavPane } from "../NavPane";
import { rootPage as useStyles } from "../shared/sharedStyles";
import { Gateways } from "./Gateways";
import { Sites } from "./Sites";
import { Systems } from "./Systems";
import { UsersRoles } from "./UsersRoles";
import { useUserRoles } from "../../hooks";
import { Tenant } from "./Tenant";
const Settings = () => {
    const styles = useStyles();
    const { tenantAdmin, siteAdmin } = useUserRoles();
    return (_jsxs(_Fragment, { children: [_jsx(NavPane, {}), _jsx("div", { className: styles.page, children: !tenantAdmin && !siteAdmin ? (_jsx(AppStateError, { authorizedError: true })) : (_jsxs(Routes, { children: [_jsx(Route, { path: "general", element: _jsx(Tenant, {}) }), _jsx(Route, { path: "systems", element: _jsx(Systems, {}) }), _jsx(Route, { path: "gateways", element: _jsx(Gateways, {}) }), _jsx(Route, { path: "sites", element: _jsx(Sites, {}) }), _jsx(Route, { path: "users", element: _jsx(UsersRoles, {}) })] })) })] }));
};
export default Settings;
